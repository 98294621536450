import { Fragment, useMemo } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import GCP from 'assets/reportAssets/gcp.png';
import { IReportsData } from 'common/interfaces';

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: '20px',
	},
	dataContainer: {
		flexDirection: 'column',
		gap: '5px',
	},
	dataTitle: {
		color: '#000000',
		textTransform: 'uppercase',
		fontSize: 8,
		fontWeight: 300,
	},
	dataFlex: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
	datasubTitle: {
		color: '#000000',
		fontSize: 12,
		fontWeight: 600,
	},
	image: {
		objectFit: 'contain',
		width: 14,
		height: 11,
	},
});

const AccountDetail = ({ data }: { data: IReportsData }) => {
	const timelineDate = useMemo(() => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		if (data.timeline === 'half-yearly') {
			const futureDate = new Date(currentDate);
			futureDate.setMonth(currentDate.getMonth() + 5);
			const futureYear = futureDate.getFullYear().toString().slice(-2);
			const currentyear = currentDate.getFullYear().toString().slice(-2);

			const formattedCurrentDate = `${new Intl.DateTimeFormat('en-US', {
				month: 'short',
			}).format(currentDate)}-${currentyear}`;
			const formattedFutureDate = `${new Intl.DateTimeFormat('en-US', {
				month: 'short',
			}).format(futureDate)}-${futureYear}`;

			// Concatenate the formatted dates
			const result = `${formattedCurrentDate} to ${formattedFutureDate}`;
			return result;
		} else {
			const formattedCurrentDate = `${new Intl.DateTimeFormat('en-US', {
				month: 'short',
			}).format(currentDate)}-${currentYear}`;
			return formattedCurrentDate;
		}
	}, [data.timeline]);

	return (
		<Fragment>
			<View style={styles.mainContainer}>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>Account Name:</Text>
					<View style={styles.dataFlex}>
						<Image src={GCP} style={styles.image} />
						<Text style={styles.datasubTitle}>{data.account_name}</Text>
					</View>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>no. of Resources</Text>
					<Text style={styles.datasubTitle}>{data.resource_count}</Text>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>Report Timeline</Text>
					<Text style={styles.datasubTitle}>{data.timeline}</Text>
				</View>
				<View style={styles.dataContainer}>
					<Text style={styles.dataTitle}>Timeline date</Text>
					<Text style={styles.datasubTitle}>{timelineDate}</Text>
				</View>
			</View>
		</Fragment>
	);
};

export default AccountDetail;

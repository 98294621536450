import { IPaginatedResponse, IUser } from 'common/interfaces';
import { client } from 'api/client';


export class UsersService {

    private static readonly path = 'api/v1/users'

    static login(token: string) {
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return client.get<{token: string}>(`${UsersService.path}/login`);
    };

    static me(token: string) {
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return client.get<IUser>(`${UsersService.path}/me`);
    }

    static gettingStarted(email: string, name: string) {
        return client.post<void>(UsersService.path, {
            email,
            ...(name && {name}),
            send_email: true,
        });
    }

    static socialLogin(idToken: string, accessToken: string) {
        return client.post<{token: string}>(`${UsersService.path}/login`, {'id_token': idToken, 'access_token': accessToken});
    }

    static callback(token: string) {
        return client.get(`${UsersService.path}/callback${token}`);
    }


    static getUsersList(page: number, size: number) {
        return client.get<IPaginatedResponse<IUser>>(`${UsersService.path}/?page=${page}&size=${size}`);
    }

    static createOrganisationUser (email: string, name: string, role: string, trial:boolean) {
        return client.post<void>(`${UsersService.path}/org`, {
            email,
            name,
            role,
            trial
        });
    }
    
    static updateOrganisationUser = (userId: string, tenantId: string, email: string, name: string, role: string, company: string, trial:boolean) => client.patch(
        `${UsersService.path}/${userId}?user_tenant_id=${tenantId}&user_id=${userId}`,
        {
            email,
            full_name: name,
            role,
            company: company,
            trial
        }
    );
    static deleteUser = (user_tenant_id: string) => client.delete(
        `${UsersService.path}/${user_tenant_id}`, {
            params : {user_tenant_id:user_tenant_id}
        }
    );
   
    static userActivityLog = (startDate?: string, endDate?:string) => client.get(
        `${UsersService.path}/activity`, 
          {params: {start_date:startDate || null,end_date:endDate || null}}
        
    );
}
import { View, StyleSheet } from '@react-pdf/renderer';
import ReportTableHeader from './ReportTableHeader';
import ReportTableRow from './ReportTableRow';
import ReportTableFooter from './ReportTableFooter';
import { IReportsData } from 'common/interfaces';

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
});

const ReportItemsTable = ({ data }: { data: IReportsData }) => (
	<View style={styles.tableContainer}>
		<ReportTableHeader timeline={data.timeline} />
		<ReportTableRow items={data.resources} />
		<ReportTableFooter data={data} />
	</View>
);

export default ReportItemsTable;

import { WS_URL } from 'common/constants';
import { useEffect, useState, createContext } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';

interface ISocketProvider {
	children: React.ReactNode;
}

const RECONNECT_INTERVAL = 60000;
export const SocketContext = createContext<WebSocket | null>(null);

export const SocketProvider = (props: ISocketProvider) => {
	const currentUser = useSelector(selectCurrentUser);
	const [ws, setWs] = useState<WebSocket | null>(null);

	useEffect(() => {
		if (currentUser) {
			const connectWebSocket = () => {
				const newWs = new WebSocket(`${WS_URL}/${currentUser.id}`);
				setWs(newWs);

				newWs.addEventListener('open', handleSocketOpen);
				newWs.addEventListener('close', handleSocketClose);
				newWs.addEventListener('error', handleSocketError);
			};

			const handleSocketOpen = (event: Event) => {
				console.log('WebSocket connection opened:');
			};

			const handleSocketClose = (event: CloseEvent) => {
				setWs(null);
				reconnectWebSocket();
			};

			const handleSocketError = (event: Event) => {
				setWs(null);
				reconnectWebSocket();
			};

			const reconnectWebSocket = () => {
				setTimeout(() => {
					connectWebSocket();
				}, RECONNECT_INTERVAL);
			};

			connectWebSocket();

			return () => {
				if (ws) {
					ws.removeEventListener('open', handleSocketOpen);
					ws.removeEventListener('close', handleSocketClose);
					ws.removeEventListener('error', handleSocketError);
					ws.close();
				}
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	return (
		<SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
	);
};

// src/serviceWorkerRegistration.ts

export function register() {
  if ('serviceWorker' in navigator) {
    // Check if service worker is already registered
    navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js')
      .then((registration) => {
        if (registration) {
          console.log('Service Worker already registered with scope:', registration.scope);
        } else {
          // Register the service worker
          navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
              console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
              console.error('Service Worker registration failed:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error during Service Worker registration check:', error);
      });
  } else {
    console.warn('Service Workers are not supported in this browser.');
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js')
      .then((registration) => {
        if (registration) {
          registration.unregister()
            .then(() => {
              console.log('Service Worker unregistered successfully.');
            })
            .catch((error) => {
              console.error('Service Worker unregistration failed:', error);
            });
        } else {
          console.log('No Service Worker found to unregister.');
        }
      })
      .catch((error) => {
        console.error('Error during Service Worker unregistration check:', error);
      });
  } else {
    console.warn('Service Workers are not supported in this browser.');
  }
}

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Arrow from 'assets/reportAssets/arrowDown.png';
import { IReportsData } from 'common/interfaces';
import { useMemo } from 'react';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 10,
		width: 100,
	},
	sumRows: {
		border: '1px solid #CBD5E0',
		backgroundColor: 'rgba(227, 236, 255, 0.8)',
	},
	savingRows: {
		width: '135px',
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
});

const ReportTableFooter = ({ data }: { data: IReportsData }) => {
	const memoizedData = useMemo(() => {
		let sumOfYR1CudSaving = 0;
		let sumOfYR3CudSaving = 0;

		for (const key in data.resources) {
			const resource = data.resources[key];
			if (resource.YR_1_cud_saving !== undefined) {
				sumOfYR1CudSaving += Number(resource.YR_1_cud_saving);
			}
			if (resource.YR_3_cud_saving !== undefined) {
				sumOfYR3CudSaving += Number(resource.YR_3_cud_saving);
			}
		}

		return {
			sumOfYR1CudSaving,
			sumOfYR3CudSaving,
		};
	}, [data.resources]);

	return (
		<View style={styles.row}>
			<Text style={{ ...styles.rowItem, width: '125px' }}>Total Savings</Text>
			<Text style={[styles.rowItem, styles.sumRows]}>
				${data.monthly_spend.toFixed(1)} /m
			</Text>
			<Text style={[styles.rowItem, styles.sumRows]}>
				${data.annual_spend.toFixed(1)} /y
			</Text>
			<View style={[styles.rowItem, styles.sumRows, styles.savingRows]}>
				<Text>${memoizedData.sumOfYR1CudSaving.toFixed(2)}</Text>
				<Image src={Arrow} style={styles.arrowStyles} />
			</View>
			<View style={[styles.rowItem, styles.sumRows, styles.savingRows]}>
				<Text>${memoizedData.sumOfYR3CudSaving.toFixed(2)}</Text>
				<Image src={Arrow} style={styles.arrowStyles} />
			</View>
		</View>
	);
};

export default ReportTableFooter;

import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: 'row',
		marginTop: 20,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	titleContainer: {
		flexDirection: 'column',
	},
	reportTitle: {
		color: '#000000',
		fontSize: 24,
		fontWeight: 700,
		textAlign: 'left',
		textTransform: 'capitalize',
	},
	reportsubTitle: {
		color: '#000000',
		fontSize: 10,
		fontWeight: 400,
		textAlign: 'left',
		textTransform: 'capitalize',
	},
	linkButton: {
		backgroundColor: '#4285F4',
		padding: '6px 12px',
		color: '#FFFFFF',
		textDecoration: 'none',
		width: 90,
		borderRadius: 12,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		textAlign: 'center',
	},
	buttonText: {
		color: '#FFFFFF',
	},
});

const ReportTitle = ({ title, subTitle }: any) => (
	<View style={styles.mainContainer}>
		<View style={styles.titleContainer}>
			<Text style={styles.reportTitle}>{title}</Text>
			<Text style={styles.reportsubTitle}>{subTitle}</Text>
		</View>
		<View>
			<Link style={styles.linkButton} src='https://eazyops.cloud/sign-in'>
				<Text style={styles.buttonText}>$ Save Now</Text>
			</Link>
		</View>
	</View>
);

export default ReportTitle;

import { IPolicyEnginePolicy, PolicyEngineResource } from 'common/interfaces';
import './DefaultPolicies.scss';

interface IDefaultPoliciesProps {
	policy: PolicyEngineResource[];
}
const DefaultPolicies = ({ policy }: IDefaultPoliciesProps) => {
	return (
		<div data-cyid='cy-default-policies' className='default-policies'>
			<label>Default thresholds</label>
			<div className='default-policies-container-criteria'>
				{policy.map((entry: PolicyEngineResource, index: number) => {
					return (
						<div
							key={index}
							className={`default-policies-container-criteria-item ${entry.actions.name}`}
						>
							<h3>{entry.actions.name.toLocaleUpperCase()}</h3>
							<div>
								{Object.entries(entry.actions.variables).map(
									([varType, variables]) => {
										return (
											<div key={varType}>
												<h4>
													{varType === 'scaling' ? 'Scale' : 'Delete'} :
													{variables.map(
														(variable: IPolicyEnginePolicy, index: number) => {
															if (index === 0) {
																return (
																	<span key={variable.id}>
																		{' '}
																		{variable.value}
																	</span>
																);
															}
															return null;
														}
													)}
												</h4>
											</div>
										);
									}
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DefaultPolicies;

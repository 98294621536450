import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	footer: {
		flexDirection: 'row',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 10,
		left: 0,
		right: 0,
		paddingHorizontal: 40,
	},
	footerText: {
		fontFamily: 'Inter',
		fontSize: 8,
		fontStyle: 'italic',
		color: '#949494',
		textAlign: 'center',
		position: 'absolute',
		bottom: 10,
		left: 0,
		right: 0,

	},
});

const Footer = () => (
	// <View fixed style={styles.footer}>
	<Text style={styles.footerText} fixed>
		The data presented in this report is for illustrative purposes and
		provides estimated savings.
	</Text>
	// </View>
);

export default Footer;

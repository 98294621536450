import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		backgroundColor: 'rgba(240, 243, 247, 0.4)',
		borderBottomWidth: 1,
		alignItems: 'center',
		padding: '0px 10px',
		height: 40,
		flexGrow: 1,
	},
	headerItem: {
		color: '#99999B',
		fontFamily: 'Inter',
		fontWeight: 500,
		fontSize: 10,
		width: 'auto',
		maxWidth: '125px',
		minWidth: '95px',
		textTransform: 'capitalize',
	},
	sumRows: {
		width: '135px',
	},
});

const ReportTableHeader = ({ timeline }: { timeline: string }) => (
	<View style={styles.container}>
		<Text style={{ ...styles.headerItem, width: '117px' }}>Resource Name</Text>
		<Text style={styles.headerItem}>
			{timeline === 'half-yearly' ? '6 Months cost' : 'Monthly Cost'}
		</Text>
		<Text style={[styles.headerItem, styles.sumRows]}>
			{timeline === 'half-yearly' ? '6 Months Savings' : 'Monthly Savings'}
		</Text>
		<Text style={styles.headerItem}>Yearly Cost</Text>
		<Text style={[styles.headerItem, styles.sumRows]}>Yearly Savings</Text>
	</View>
);

export default ReportTableHeader;

import React, { useState } from 'react';
import {
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	SelectChangeEvent,
} from '@mui/material';
import { ITableColumn } from 'components/Table/Table';
import { ReactComponent as SettingsIcon } from 'assets/svgs/Settings.svg';
import './ColumnsDropdown.scss';

interface ColumnDropdownProps {
	columns: ITableColumn<any>[];
	visibleColumns: string[] | undefined;
	onToggleColumnVisibility: (columnName: string) => void;
	data_cyid: string;
}

const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
	columns,
	visibleColumns,
	onToggleColumnVisibility,
	data_cyid = 'cy-columns-dropdown',
}) => {
	const [open, setOpen] = useState(false);

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		const selectedColumnNames = event.target.value as string[];
		// Handle column visibility changes based on selection
		columns.forEach((column) => {
			const columnName = column.name;
			const shouldShow = selectedColumnNames.includes(columnName);
			if (shouldShow !== visibleColumns?.includes(columnName)) {
				onToggleColumnVisibility(columnName);
			}
		});
	};

	return (
		<div data-cyid={data_cyid} className='columns-dropdown'>
			<Select
				labelId='column-dropdown-label'
				id='column-dropdown'
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				value={visibleColumns}
				onChange={handleChange}
				multiple
				renderValue={() => {
					return (
						<div className='columns-dropdown__title'>
							<SettingsIcon color='#FFF' />
							<span>Columns</span>
						</div>
					);
				}}
				IconComponent={() => null}
				className='select-column-dropdown'
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					autoFocus: false,
					classes: { list: 'columns-dropdown__menu-list' },
					className: 'columns-dropdown__menu-list',
				}}
			>
				{columns
					.filter((column) => column.name)
					.map((column, index) => (
						<MenuItem
							key={column.name}
							value={column.name}
							disableRipple
							disableTouchRipple
						>
							{index === 0 ? (
								<FormControlLabel
									disabled
									disableTypography
									aria-disabled
									control={
										<Checkbox
											checked={true}
											disabled
											value={column.name}
											disableFocusRipple
											disableRipple
											disableTouchRipple
										/>
									}
									label={column.name}
								/>
							) : (
								<FormControlLabel
									control={
										<Checkbox
											checked={visibleColumns?.includes(column.name)}
											value={column.name}
										/>
									}
									label={column.name}
								/>
							)}
						</MenuItem>
					))}
			</Select>
		</div>
	);
};

export default ColumnDropdown;

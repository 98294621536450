import './Table.scss';
import { classNames } from 'common/helpers';
import React, {
	MouseEvent,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Checkbox,
	FormControlLabel,
	Collapse,
	IconButton,
} from '@mui/material';
import { CustomPagination } from 'components/CustomPagination/CustomPagination';
import { ExpandMore, ExpandLess, ArrowUpwardOutlined, ArrowDownwardOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import Pagination from './Pagination/Pagination';
import dayjs from 'dayjs';

const rowHeight = 60;
const headerHeight = 40;
const buttonsHeight = 35;

export interface ITableColumn<T> {
	id?: string;
	name: string;
	element: (row: T, rowIndex?: number) => ReactElement;
	minWidth?: string;
	maxWidth?: string;
	width?: string;
	className?: string;
	sortable?: boolean;
	sortKey?: string;
	showSum?: boolean;
	unit?: string | ((row: T) => string);
	value?: (row: T) => string | number | null | undefined;
}

interface TableProps<T> {
	columns: ITableColumn<T>[];
	visibleColumns?: string[];
	rows: T[];
	activePage?: number;
	onPageChange?: (page: number) => void;
	totalCount: number;
	itemsPerPage?: number;
	onRowClick?: (row: T) => void;
	onCellClick?: (
		e: MouseEvent<HTMLTableCellElement>,
		columnName: string,
		row: T
	) => void;
	isSkeleton?: boolean;
	placeholderText?: string;
	className?: string;
	selectedRows?: Set<string>;
	onRowSelect?: (row: T) => void;
	showSelectBox?: boolean;
	setPager?: () => void;
	pager?: any;
	totalRecordCount?: number;
	totalPages?: number;
	recordPerPage?: number;
	footer?: boolean;
	data_cyid: string;
	searchString?: string;
	collapsible?: boolean;
	collapsibleContent?: (row: T) => ReactElement;
	onCollapse?: (row?: T) => void;
}

function Table<T extends { id: string; name?: string }>({
	columns,
	rows,
	activePage = 0,
	onPageChange = () => { },
	totalCount,
	itemsPerPage,
	onRowClick,
	onCellClick,
	isSkeleton = false,
	placeholderText = 'No data to show',
	className,
	selectedRows = new Set(),
	onRowSelect = (row: T) => { },
	showSelectBox = false,
	setPager,
	pager,
	totalRecordCount,
	totalPages,
	recordPerPage,
	footer,
	data_cyid = 'cy-table-component',
	visibleColumns,
	searchString = '',
	collapsible = false,
	collapsibleContent,
	onCollapse = (row?: T) => { },
}: TableProps<T>) {
	const [tableHeight, setTableHeight] = useState<number>(0);
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
	const tableRef = useRef<HTMLDivElement>(null);

	const normalizedItemsPerPage =
		itemsPerPage ||
		(tableHeight
			? Math.floor((tableHeight - headerHeight - buttonsHeight) / rowHeight)
			: 2);

	const buttons =
		totalCount && normalizedItemsPerPage
			? Array.from(
				{ length: Math.ceil(totalCount / normalizedItemsPerPage) },
				(_, i) => i
			)
			: [];
	const start = activePage * normalizedItemsPerPage;
	const end = start + normalizedItemsPerPage;

	const getInitialSortColumn = (columns: any[]) => {
		const sortableColumn = columns.find((column) => column.sortable);
		if (sortableColumn) {
			return sortableColumn.sortKey || sortableColumn.id || sortableColumn.name;
		}
		return null;
	};
	const [sortColumn, setSortColumn] = useState<string | null>(
		getInitialSortColumn(columns)
	);

	const [sortDirection, setSortDirection] = useState<
		'asc' | 'desc'
	>('asc');

	//sort functionality to sort data base on the given order
	const handleSort = (column: string) => {
		const newSortDirection = column === sortColumn
			? sortDirection === 'asc' ? 'desc' : 'asc'
			: 'asc';

		setSortColumn(column);
		setSortDirection(newSortDirection);
	};

	//shows skeleton rows while loading data from the server
	const skeletonRows = Array.from(
		{ length: normalizedItemsPerPage },
		(_, i) => i
	).map((v) => (
		<tr key={'ghost-table-row' + v} className='ghost-table-row'>
			{columns.map((column) => (
				<td key={column.name}></td>
			))}
		</tr>
	));

	/**
	 * Checks if a given date string is a valid date.
	 * @param dateString - The date string to validate.
	 * @returns A boolean indicating whether the date string is valid.
	 */
	function isValidDate(dateString: string): boolean {
		const date = dayjs(dateString);

		return !isNaN(date.valueOf());
	}

	/**
	 * Compares two dates and returns a number based on the sort direction.
	 *
	 * @param a - The first date to compare.
	 * @param b - The second date to compare.
	 * @returns A number indicating the comparison result.
	 */
	const compareDates = useCallback(
		(a: string, b: string): number => {
			if (sortDirection === 'asc') {
				return dayjs(a).valueOf() - dayjs(b).valueOf();
			} else {
				return dayjs(b).valueOf() - dayjs(a).valueOf();
			}
		},
		[sortDirection]
	);

	/**
	 * Finds the value associated with a given key in a nested object.
	 * @param obj - The object to search in.
	 * @param key - The key to search for.
	 * @returns The value associated with the key, or undefined if the key is not found.
	 */
	function findKeyValue(obj: any, key: string): any {
		let result: any;
		function search(o: any) {
			if (o && typeof o === 'object') {
				if (key in o) {
					result = o[key];
					return;
				}
				for (const k of Object.keys(o)) {
					search(o[k]);
					if (result !== undefined) break;
				}
			}
		}

		search(obj);
		return result;
	}

	/**
	 * Represents an array of sorted rows based on the specified sorting criteria.
	 *
	 * @remarks
	 * The `sortedRows` array is generated using the `rows` array and the provided sorting parameters.
	 * If the `rows` array is empty, an empty array will be returned.
	 *
	 * @returns An array of sorted rows.
	 */
	const sortedRows = useMemo(() => {
		if (!rows.length) return [];

		const columnToSortBy = sortColumn || 'name';

		return rows.slice(start, end).sort((rowA, rowB) => {
			const a = findKeyValue(rowA, columnToSortBy);
			const b = findKeyValue(rowB, columnToSortBy);

			if (a === undefined || b === undefined) {
				return 0; // or some default logic
			}

			if (typeof a === 'string' && typeof b === 'string') {
				if (isValidDate(a) && isValidDate(b)) {
					return compareDates(a, b);
				}
				return sortDirection === 'desc'
					? b.localeCompare(a)
					: a.localeCompare(b);
			}

			if (typeof a === 'number' && typeof b === 'number') {
				return sortDirection === 'desc'
					? b - a
					: a - b;
			}

			// Fallback to string comparison for other types
			return sortDirection === 'desc'
				? String(b).localeCompare(String(a))
				: String(a).localeCompare(String(b));
		});
	}, [compareDates, end, rows, sortColumn, sortDirection, start]);

	//set height of the table on render
	useEffect(() => {
		setTableHeight(tableRef.current?.clientHeight || 0);
	}, []);

	const selectedColumns = useMemo(() => {
		return visibleColumns
			? columns.filter((column) => visibleColumns.includes(column.name))
			: columns;
	}, [columns, visibleColumns]);

	//filter rows based on the search string
	const filteredRows = useMemo(() => {
		return sortedRows.filter((row) => {
			const trimmedSearchString = searchString?.trim().toLowerCase();

			return trimmedSearchString
				? row.name?.toLowerCase().includes(trimmedSearchString)
				: true;
		});
	}, [searchString, sortedRows]);

	/**
	 * Calculates the sum of values in the sorted rows based on the specified columns.
	 * The sum is formatted and displayed with the corresponding unit.
	 *
	 * @returns An array of JSX elements representing the sum of values for each column.
	 */
	const sumRow = useMemo(() => {
		const isSortedRowsArray = Array.isArray(sortedRows);
		const boldStyle = { fontWeight: 'bold' };

		return columns
			.filter((column) => column.showSum)
			.map((column) => {
				const firstRow = sortedRows[0];
				const unit =
					typeof column.unit === 'function'
						? column.unit(firstRow)
						: column.unit || '';
				const upperUnit = unit.toUpperCase();

				const sum = isSortedRowsArray
					? sortedRows.reduce((acc, row) => {
						if (column.value) {
							const value = Number(column.value(row));
							return acc + (isNaN(value) ? 0 : value);
						}
						return acc;
					}, 0)
					: 0;
				const formattedSum = sum.toFixed(2);

				const displayText = unit.includes('$')
					? ` ${upperUnit} ${formattedSum}`
					: `${formattedSum} ${upperUnit}`;

				return (
					<span
						key={column.id || column.name}
						className={column.className}
						style={boldStyle}
					>
						{displayText}
					</span>
				);
			});
	}, [columns, sortedRows]);

	/**
	 * Toggles the expansion state of a row in the table.
	 * @param id - The ID of the row to toggle.
	 */
	const toggleRow = (id: string) => {
		setExpandedRows((prev) => {
			const newExpandedRows = new Set<string>();
			if (!prev.has(id)) {
				newExpandedRows.add(id);
			}
			return newExpandedRows;
		});
		onCollapse && onCollapse(rows.find((row) => row.id === id));
	};

	return (
		<div
			data-cyid={data_cyid}
			ref={tableRef}
			className={classNames('table-component', className)}
		>
			<div className='table-component-wrapper'>
				{!isSkeleton && !filteredRows.length ? (
					<div className='placeholder'>{placeholderText}</div>
				) : (
					<table>
						<thead>
							<tr>
								{selectedColumns &&
									selectedColumns.map((column, index) => (
										<th
											key={column.id || column.name}
											className={column.className}
											style={{
												minWidth: column.minWidth,
												maxWidth: column.maxWidth,
												width: column.width,
											}}
											onClick={() =>
												column.sortable &&
												handleSort(column.sortKey || column.id || column.name)
											}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												{column.name}

												{column.sortable && (
													<>
														{sortDirection === 'desc' && sortColumn === (column.sortKey || column.id || column.name) ? (
															<ArrowDownwardOutlined
																sx={{ height: '17px', width: '17px', marginLeft: '5px' }}
																onClick={() => handleSort(column.sortKey || column.id || column.name)}
															/>
														) : (
															<ArrowUpwardOutlined
																sx={{ height: '17px', width: '17px', marginLeft: '5px' }}
																onClick={() => handleSort(column.sortKey || column.id || column.name)}
															/>
														)}
													</>
												)}
											</div>
										</th>
									))}
							</tr>
						</thead>
						<tbody>
							{isSkeleton
								? skeletonRows
								: filteredRows.length &&
								filteredRows.map((row, i) => {
									const isExpanded = expandedRows.has(row.id);
									return (
										<React.Fragment key={row.id || row.name}>
											<tr onClick={() => onRowClick?.(row)}>
												{selectedColumns &&
													selectedColumns.map((column, ind) => {
														return ind === 0 ? (
															<td key={(column.id || column.name) + row.id}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '6px',
																	}}
																>
																	{collapsible && (
																		<IconButton
																			onClick={() => toggleRow(row.id)}
																			aria-expanded={isExpanded}
																			aria-label='expand row'
																			sx={{
																				height: '15px',
																				width: '15px',
																			}}
																		>
																			{isExpanded ? (
																				<ExpandLess />
																			) : (
																				<ExpandMore />
																			)}
																		</IconButton>
																	)}
																	{showSelectBox && (
																		<FormControlLabel
																			className='select_all'
																			control={<Checkbox />}
																			onChange={() => onRowSelect(row)}
																			checked={selectedRows.has(row?.id)}
																			label=''
																			key={
																				(column.id || column.name) + row.id
																			}
																			sx={{ margin: 0 }}
																		/>
																	)}
																	<span>{column.element(row, i + 0)}</span>
																</div>
															</td>
														) : (
															<td
																key={(column.id || column.name) + row.id}
																onClick={(e) =>
																	onCellClick?.(e, column.name, row)
																}
																className={column.className}
															>
																{column.element(row, i + start)}
															</td>
														);
													})}
											</tr>
											{collapsible && expandedRows.has(row.id) && (
												<tr style={{ paddingBottom: 0, paddingTop: 0 }}>
													<td
														colSpan={columns.length + 1}
														className='collapsed-td'
													>
														<Collapse
															in={expandedRows.has(row.id)}
															timeout='auto'
															unmountOnExit
														>
															<Box sx={{ margin: 1, width: '100%' }}>
																{collapsibleContent &&
																	collapsibleContent(row)}
															</Box>
														</Collapse>
													</td>
												</tr>
											)}
										</React.Fragment>
									);
								})}
						</tbody>
						{footer && (
							<tfoot>
								{sumRow && sumRow.length > 0 && (
									<tr>
										{collapsible && <td />}
										{columns.map((column) => {
											const sumContent = sumRow.find(
												(sumCell) => sumCell.key === (column.id || column.name)
											);
											return (
												<td
													key={column.id || column.name}
													className={column.className}
												>
													{sumContent
														? React.cloneElement(sumContent, {
															key: column.id || column.name,
														})
														: null}
												</td>
											);
										})}
									</tr>
								)}
							</tfoot>
						)}
					</table>
				)}
			</div>

			{totalCount > 0 && totalCount > normalizedItemsPerPage && !pager && (
				<Pagination
					activePage={activePage}
					onPageChange={onPageChange}
					buttons={buttons}
				/>
			)}
			{pager && (
				<CustomPagination
					setPager={setPager}
					pager={pager}
					totalRecordCount={totalRecordCount}
					totalPages={totalPages}
					recordPerPage={recordPerPage}
					data_cyid='cy-pagination-stack-table-component'
				/>
			)}
		</div>
	);
}

export { Table };
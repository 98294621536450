import {
	ReactElement,
	createContext,
	useMemo,
	useState,
	useEffect,
	useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import {
	INotification,
	Notification,
	NotificationSeverity,
} from './Notification/Notification';

const NotificationsContext = createContext({
	open: (text: string, severity: NotificationSeverity) => {},
});

function generateUEID() {
	const random = (Math.random() * 46656) | 0;
	const first = ('000' + random.toString(36)).slice(-3);
	const second = ('000' + random.toString(36)).slice(-3);

	return first + second;
}

const NotificationsProvider = ({ children }: { children: ReactElement }) => {
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [currentNotification, setCurrentNotification] =
		useState<INotification | null>(null);

	const open = useCallback(
		(text: string, severity: NotificationSeverity) => {
			setNotifications((currentNotifications) => {
				if (
					currentNotifications.some(
						(n) => n.text === text && n.severity === severity
					)
				) {
					return currentNotifications;
				}

				// If it doesn't, add a new notification
				const newNotification = { id: generateUEID(), text, severity };
				const updatedNotifications = [...currentNotifications, newNotification];

				if (!currentNotification) {
					// If there's no current notification, set the new one as the current notification.
					setCurrentNotification(newNotification);
				}

				return updatedNotifications;
			});
		},
		[currentNotification]
	);

	const close = (id: string) => {
		setNotifications((currentNotifications) => {
			const updatedNotifications = currentNotifications.filter(
				(notification) => notification.id !== id
			);

			if (currentNotification && currentNotification.id === id) {
				// If the closed notification is the current notification, set the next one as the current notification.
				setCurrentNotification(updatedNotifications[0] || null);
			}

			return updatedNotifications;
		});
	};

	const contextValue = useMemo(() => ({ open }), [open]);

	// Add a useEffect to handle notification changes and set the current notification accordingly.
	useEffect(() => {
		setCurrentNotification(notifications[0] || null);
	}, [notifications]);

	return (
		<NotificationsContext.Provider value={contextValue}>
			{children}

			{createPortal(
				<>
					{currentNotification && (
						<Notification
							key={currentNotification.id}
							text={currentNotification.text}
							close={() => close(currentNotification.id)}
							severity={currentNotification.severity}
						/>
					)}
				</>,

				document.body
			)}
		</NotificationsContext.Provider>
	);
};

export { NotificationsProvider, NotificationsContext };

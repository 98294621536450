import { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Arrow from 'assets/reportAssets/arrowDown.png';
import Cluster from 'assets/reportAssets/Cluster.png';
import Volumes from 'assets/reportAssets/Volume.png';
import Computes from 'assets/reportAssets/Compute.png';
import Database from 'assets/reportAssets/Databases.png';
import Logs from 'assets/reportAssets/Logs.png';
import { IReportResource } from 'common/interfaces';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		borderBottomWidth: 1,
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		width: 100,
	},

	savingRows: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderBottom: 'none',
		width: '135px',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
	flexRow: {
		padding: '10px 10px',
		flexDirection: 'row',
		gap: '5px',
		alignItems: 'center',
		width: '125px',
	},
	resourceIcon: { width: '13.75px' },
});

const iconMapping: Record<string, string> = {
	clusters: Cluster,
	instances: Computes,
	disks: Volumes,
	databases: Database,
	logs: Logs,
};
const labelMapping: Record<string, string> = {
	disks: 'Volume',
	instances: 'Compute',
	databases: 'Database',
	clusters: 'Cluster',
	logs: 'Log',
	pods: 'Pod',
	deployments: 'Deployment',
};
const ReportTableRow = ({ items }: any) => {
	const rows = Object.keys(items).map((key: string, index: number) => {
		const item: IReportResource = items[key];
		const IconComponent = iconMapping[key];
		const monthlySavingPercentage =
			item.monthly_cost > 0
				? parseFloat(
						((item.monthly_saving / item.monthly_cost) * 100).toFixed(2)
				  )
				: 0;
		const annualSavingPercentage =
			item.annual_cost > 0
				? parseFloat(((item.annual_saving / item.annual_cost) * 100).toFixed(2))
				: 0;

		return (
			<View style={styles.row} key={key}>
				<View style={{ ...styles.flexRow }}>
					<Image style={styles.resourceIcon} src={IconComponent} />
					<Text style={{ ...styles.rowItem, padding: 0 }}>
						{labelMapping[key]}
					</Text>
				</View>
				<Text style={styles.rowItem}>${item.monthly_cost.toFixed(2)}</Text>
				<View style={[styles.rowItem, styles.savingRows]}>
					<Text> ${item.monthly_saving.toFixed(2)}</Text>
					<Image src={Arrow} style={styles.arrowStyles} />
					<Text style={styles.savingPercentage}>
						{monthlySavingPercentage}%
					</Text>
				</View>
				<Text style={styles.rowItem}>${item.annual_cost.toFixed(2)}</Text>
				<View style={[styles.rowItem, styles.savingRows]}>
					<Text> ${item.annual_saving.toFixed(2)}</Text>
					<Image src={Arrow} style={styles.arrowStyles} />
					<Text style={styles.savingPercentage}>{annualSavingPercentage}%</Text>
				</View>
			</View>
		);
	});
	return <Fragment>{rows}</Fragment>;
};

export default ReportTableRow;

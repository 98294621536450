import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { TOKEN_KEY } from 'common/constants';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { AppDispatch } from 'store';
import { getCurrentUserInfo, userSignOut } from 'store/app/app-thunks';
import { CoreRoutes } from 'routing/routing-paths';
import { getCookie } from 'common/helpers';
import { jwtDecode } from 'jwt-decode';
import { NotificationsListProvider } from 'components/Notifications/NotificationsList/NotificationsListContext';
interface AuthGuardProps {
	children: ReactElement;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
	const dispatch = useDispatch<AppDispatch>();
	const [authStatus, setAuthStatus] = useState({
		isChecked: false,
		isAllowed: false,
	});

	const checkAuth = async () => {
		let isChecked = false;
		let isAllowed = false;

		const token = getCookie(TOKEN_KEY);

		if (token) {
			const decodedToken = jwtDecode<{ exp: number }>(token);

			if (decodedToken && decodedToken.exp) {
				const expirationDate = new Date(decodedToken.exp * 1000);
				const currentDate = new Date();

				if (expirationDate < currentDate) {
					await dispatch(userSignOut());
					isChecked = true;
					isAllowed = false;
				} else {
					try {
						await dispatch(getCurrentUserInfo(token));
						isChecked = true;
						isAllowed = true;
					} catch (error) {
						await dispatch(userSignOut());
						isChecked = true;
						isAllowed = false;
					}
				}
			} else {
				await dispatch(userSignOut());
				isChecked = true;
				isAllowed = false;
			}
		} else {
			await dispatch(userSignOut());
			isChecked = true;
			isAllowed = false;
		}

		setAuthStatus({ isChecked, isAllowed });
	};

	useEffect(() => {
		checkAuth();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const result = authStatus.isAllowed ? (
		<NotificationsListProvider>{children}</NotificationsListProvider>
	) : (
		<Navigate to={CoreRoutes.SIGN_IN}></Navigate>
	);

	return <>{authStatus.isChecked ? result : <LoadingSpinner />}</>;
};

export default AuthGuard;

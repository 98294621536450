import './Dropdown.scss';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from 'assets/svgs/arrow-down.svg';
import { classNames } from 'common/helpers';
import { DropdownList } from './DropdownList/DropdownList';
import { CircularProgress, Popover } from '@mui/material';

interface DropdownProps<T> {
	options: T[];
	placeholder?: string | React.ReactNode;
	selected?: T | null;
	onOptionSelected?: (option: T) => void;
	className?: 'input' | 'dropdown' | string;
	fetchData?: { url: string; params: any };
	verticalAlignment?: 'bottom' | 'top';
	horizontalAlignment?: 'left' | 'right';
	dropdownPopoverClassName?: string;
	disabled?: boolean;
	data_cyid: string;
	isLoading?: boolean;
}

function Dropdown<T extends { id: string; name: string }>({
	options,
	placeholder = 'Not Selected',
	selected = null,
	onOptionSelected = (option: T) => { },
	className = 'dropdown',
	verticalAlignment = 'bottom',
	horizontalAlignment = 'left',
	dropdownPopoverClassName,
	disabled = false,
	data_cyid = 'cy-dropdown-component',
	isLoading = false
}: DropdownProps<T>) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const [selectedOption, setSelectedOption] = useState<T | null>(null);
	const inputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (selected) {
			setSelectedOption(selected);
		}
	}, [selected]);

	const onShowMenu = (element: HTMLDivElement) => {
		setAnchorEl(element);
	};

	const onHideMenu = () => {
		setAnchorEl(null);
	};

	const onSelectionChange = (option: T) => {
		setSelectedOption(option);
		onOptionSelected(option);
		onHideMenu();
	};

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target as Node)
			) {
				onHideMenu();
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [inputRef]);

	const onToggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!isLoading) {
			if (anchorEl === e.currentTarget) {
				onHideMenu();
			} else {
				onShowMenu(e.currentTarget);
			}
		}
	};

	return (
		<div
			className={classNames(
				'dropdown-component',
				className,
				disabled && 'dropdown-component__disabled'
			)}
			data-cyid={data_cyid}
			ref={inputRef}
			onClick={onToggleDropdown}
		>
			<div className='dropdown-component-title' aria-disabled={disabled}>
				<div className={'dropdown-component-title-value'}>
					<span>
						{selectedOption?.name || placeholder}
					</span>

					{isLoading && <CircularProgress size={12} color='inherit' />}
				</div>
				<ArrowDown className='dropdown-component-tools' />
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: verticalAlignment,
					horizontal: horizontalAlignment,
				}}
				sx={{
					'& .MuiPopover-paper': {
						minWidth: `${inputRef.current?.clientWidth}px`,
					},
				}}
				data-cyid='cy-dropdown-popover'
				className={dropdownPopoverClassName}
			>
				<DropdownList
					options={options}
					onOptionSelected={onSelectionChange}
					selected={selectedOption}
				/>
			</Popover>
		</div>
	);
}

export { Dropdown };

import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';
import { NotificationsProvider } from 'components/Notifications/NotificationContext';
import 'react-toastify/dist/ReactToastify.css';
import { SocketProvider } from 'components/Websocket/SocketProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<SocketProvider>
				<NotificationsProvider>
					<App />
				</NotificationsProvider>
			</SocketProvider>
		</PersistGate>
	</Provider>
);

// Register the service worker
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Avatar } from '@mui/material';
import './UserImage.scss';
import { useCallback, useMemo } from 'react';

interface UserImageProps {
	size: number;
	userName: string;
	src?: string | null;
	color?: string | null;
}

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function UserImage({ size, userName, src, color }: UserImageProps) {
	const nameArray = useMemo(() => userName?.split(' '), [userName]);
	const initials = useMemo(
		() => (nameArray?.[0]?.[0] || '') + (nameArray?.[1]?.[0] || ''),
		[nameArray]
	);

	const stringAvatar = useCallback(() => {
		return {
			children: initials.toUpperCase(),
		};
	}, [initials]);

	return (
		userName && (
			<Avatar
				alt={userName}
				src={src || ''}
				{...stringAvatar()}
				sx={{
					width: size,
					height: size,
					bgcolor: color || stringToColor(userName), // Directly apply the color here
				}}
			/>
		)
	);
}

export { UserImage };

import React from 'react';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import './PrivacyPolicies.scss';
import StickyHeader from './StickyHeader';

interface TermsAndConditionsProps {
	companyName: string;
	websiteName: string;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
	websiteName,
}) => {
	return (
		<Container className='privacy-policies-container'>
			<StickyHeader />
			<Container
				sx={{ p: '20px' }}
				className='privacy-policies-container-content'
			>
				<Typography variant='h4' gutterBottom>
					Terms and Conditions
				</Typography>

				<Box mb={2}>
					<Typography paragraph>
						Welcome to{' '}
						<a
							href='https://www.eazyops.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<b>{websiteName}</b>
						</a>
						! By using this website, you agree to abide by the following terms
						and conditions. If you do not agree, please refrain from using the
						site.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>Cookies:</Typography>
					<Typography>
						We use cookies to enhance your browsing experience. By using{' '}
						<a
							href='https://www.eazyops.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<b>{websiteName}</b>
						</a>
						, you consent to the use of cookies as outlined in our Privacy
						Policy.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>License:</Typography>
					<Typography>
						All content on{' '}
						<a
							href='https://www.eazyops.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<b>{websiteName}</b>
						</a>
						is the property of{' '}
						<a
							href='https://www.eazyops.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<b>{websiteName}</b>
						</a>{' '}
						or its licensors. You may use the material for personal use only,
						and you must not redistribute, reproduce, or modify it without
						permission.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>User Comments:</Typography>
					<Typography>
						Users may post comments, but{' '}
						<a
							href='https://www.eazyops.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<b>{websiteName}</b>
						</a>{' '}
						is not responsible for their content. We reserve the right to remove
						any comments deemed inappropriate or offensive.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>Hyperlinking:</Typography>
					<Typography>
						Certain organizations may link to our website with permission.
						Unauthorized framing of our webpages is prohibited.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>Content Liability:</Typography>
					<Typography>
						We are not liable for content on external websites linked to ours.
						You agree to defend us against any claims arising from your website.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>Reservation of Rights:</Typography>
					<Typography>
						We reserve the right to request removal of any links to our website.
						By linking to us, you agree to comply with our terms.
					</Typography>
				</Box>

				<Box mb={2}>
					<Typography variant='h5'>Removal of Links:</Typography>
					<Typography>
						If you find offensive links on our website, please inform us,
						although we're not obligated to remove them.
					</Typography>
				</Box>

				<Box mb={2}>
					<Typography variant='h5'>Disclaimer:</Typography>
					<Typography>
						We do not guarantee the accuracy or availability of information on
						this website. Our liability is limited to the extent permitted by
						law.
					</Typography>
				</Box>
				<Typography paragraph>
					By continuing to use{' '}
					<a
						href='https://www.eazyops.com/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<b>{websiteName}</b>
					</a>
					, you acknowledge and accept these terms and conditions.
				</Typography>
			</Container>
		</Container>
	);
};

export default TermsAndConditions;

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		backgroundColor: 'rgba(240, 243, 247, 0.4)',
		borderBottomWidth: 1,
		alignItems: 'center',
		padding: '0px 10px',
		height: 40,
		flexGrow: 1,
	},
	headerItem: {
		color: '#99999B',
		fontFamily: 'Inter',
		fontWeight: 500,
		fontSize: 10,
		maxWidth: '175px',
		minWidth: '175px',
		// width: 100,
		textTransform: 'capitalize',
	},
});

const ProjectTableHeader = () => (
	<View style={styles.container}>
		<Text style={styles.headerItem}>Project Name</Text>
		<Text style={styles.headerItem}>Resource Count</Text>
		<Text style={styles.headerItem}>Monthly Spend</Text>
	</View>
);

export default ProjectTableHeader;

import './DropdownList.scss';
import { useEffect, useMemo, useState } from 'react';
import { classNames } from 'common/helpers';

interface DropdownListProps<T> {
	options: T[];
	selected?: T | null;
	onOptionSelected?: (option: T) => void;
	className?: string;
}

function DropdownList<T extends { id: string; name: string }>({
	options,
	onOptionSelected = (T) => {},
	selected = null,
	className,
}: DropdownListProps<T>) {
	const [activeOption, setActiveOption] = useState<T | null>(null);

	useEffect(() => {
		const keyPressHandler = (e: KeyboardEvent) => {
			e.stopPropagation();
			switch (e.key) {
				case 'ArrowDown':
				case 'ArrowUp':
					const isArrowDown = e.key === 'ArrowDown';
					const index = options.findIndex(
						(option) => option.id === activeOption?.id
					);
					const nextIndex = isArrowDown ? index + 1 : index - 1;
					if (nextIndex >= 0 && nextIndex < options.length) {
						setActiveOption(options[nextIndex]);
					} else {
						setActiveOption(options[isArrowDown ? 0 : options.length - 1]);
					}
					break;
				case 'Enter':
					if (activeOption) {
						onOptionSelected(activeOption);
					}
					break;
			}
		};

		window.addEventListener('keydown', keyPressHandler);

		return () => {
			window.removeEventListener('keydown', keyPressHandler);
		};
	}, [options, activeOption, onOptionSelected]);

	const optionsList = useMemo(() => {
		return options;
	}, [options]);

	return (
		<div
			data-cyid='cy-dropdown-menu-component'
			className={classNames('dropdown-menu-component', className)}
		>
			{optionsList ? (
				optionsList.map((option) => (
					<div
						onClick={(ev) => {
							ev.stopPropagation();
							onOptionSelected(option);
						}}
						key={option.id}
						className={classNames(
							'dropdown-menu-component-item',
							option.id === selected?.id && 'selected',
							option.id === activeOption?.id && 'active'
						)}
					>
						{option.name}
					</div>
				))
			) : (
				<div className={classNames('dropdown-menu-component-item')}>
					No options available
				</div>
			)}
		</div>
	);
}

export { DropdownList };

import { client } from 'api/client';
import { AxiosError, AxiosResponse } from 'axios';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { useNotifications } from 'components/Notifications/useNotifications';
import { CoreRoutes } from 'routing/routing-paths'; 

let isUnauthorized = false;

export function useRequestsErrorsInterceptor() {  
    const notification = useNotifications();
    client.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError<{message: string, detail:string}>) => {
            const { response } = error; 
            if (response?.status === 401 && !response?.config.url?.includes('api/connector/access_token')) {
                if (!isUnauthorized) {
                    notification.open('Session expired. Please login again', NotificationSeverity.ERROR);
                     isUnauthorized = true;
                }
                window.location.href = CoreRoutes.SIGN_IN;
            } else {
                if(!response?.config.url?.includes('api/connector/google_authorize_code') && (!response?.config.url?.includes('api/connector/')  && response?.config.method === 'delete')) 
                    notification.open(`${response?.data?.message || response?.data?.detail || 'Something went wrong'}`, NotificationSeverity.INFO)
            }
            return Promise.reject(error);
        }
    );
}
import './ValidationMessage.scss';

interface ValidationMessageProps {
    text: string;
    shown: boolean;
}

function ValidationMessage({ text, shown }: ValidationMessageProps) {
    return <>
        {shown && <div data-cyid='cy-validation-message-component' className='validation-message-component'>{text}</div>}
    </>
}

export { ValidationMessage };
import React, { useState } from 'react';
import {
	PieChart,
	Pie,
	Sector,
	ResponsiveContainer,
	PieProps,
	Cell,
} from 'recharts';
import './DynamicPieChart.scss';

export interface DataProps {
	name: string;
	value: number;
}

interface ChartProps {
	data: DataProps[];
	innerRadius?: number;
	outerRadius?: number;
	activeInd?: number;
	height?: number;
	width?: number;
}

interface RenderActiveShapeProps {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	startAngle: number;
	endAngle: number;
	fill: string;
	payload: DataProps;
	percent: number;
	value: number;
}

const colors = [
	'#8884d8',
	'#82ca9d',
	'#ffc658',
	'#ff8042',
	'#00C49F',
	'#FFBB28',
	'#FF8042',
	'#0088FE',
	'#549700',
	'#BA180F',
	'#0000',
];

const renderActiveShape = (props: RenderActiveShapeProps) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill='none'
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill='#333'
			>{` ${value}`}</text>

			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill='#999'
			>
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

const DynamicPieChart: React.FC<ChartProps> = ({
	data,
	innerRadius,
	outerRadius,
	activeInd,
	height,
	width,
}) => {
	const [activeIndex, setActiveIndex] = useState(activeInd || 0);

	const onPieEnter: PieProps['onMouseEnter'] = (_, index) => {
		setActiveIndex(index);
	};

	return (
		<ResponsiveContainer width='100%' height='100%'>
			<PieChart width={height || 400} height={width || 400}>
				<Pie
					activeIndex={activeIndex}
					activeShape={renderActiveShape as any}
					data={data}
					cx='50%'
					cy='50%'
					innerRadius={innerRadius || 90}
					outerRadius={outerRadius || 120}
					fill='#8884d8'
					dataKey='value'
					onMouseEnter={onPieEnter}
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default DynamicPieChart;

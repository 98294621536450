import { Suspense, lazy } from 'react';
import {
	AccountActionsChildrenRoutes,
	AccountChildrenRoutes,
	AccountInventoryChildrenRoutes,
	CoreRoutes,
	DiscoveryChildrenRoutes,
	GKEChildrenRoutes,
	ViewTypeRoutes,
} from './routing-paths';
import { Navigate } from 'react-router';
import { ResourceActionType, ResourceType } from 'common/interfaces';
import {
	gkeResourcePageConfig,
	resourcePageConfig,
} from './resourcePageRouterConfig';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

const DiscoveryPage = lazy(
	() => import('../features/DiscoveryPage/DiscoveryPage')
);
const AccountPage = lazy(
	() => import('../features/DiscoveryPage/AccountPage/AccountPage')
);
const AccountsOverview = lazy(
	() => import('../features/DiscoveryPage/AccountsOverview/AccountsOverview')
);
const AccountsCards = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountsOverview/AccountsCards/AccountsCards'
		)
);
const AccountsList = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountsOverview/AccountsList/AccountsList'
		)
);
const AccountsMap = lazy(
	() =>
		import('../features/DiscoveryPage/AccountsOverview/AccountsMap/AccountsMap')
);
const AccountOverview = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountOverview/AccountOverview'
		)
);
const InventoryPage = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AccountInventoryPage/InventoryPage'
		)
);
const Allinventories = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/AllInventories'
		)
);

const AllVolumes = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/Volumes'
		)
);
const AllComputes = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/Computes'
		)
);
const AllDatabases = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/Databases'
		)
);
const AllLogs = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/Logs'
		)
);
const AllClusters = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/Clusters'
		)
);

const InventoryOrganisation = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountInventory/AllInventories/InventoryOrganisation'
		)
);
const AccountActions = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountActions'
		)
);
const AccountVMS = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountVMs/AccountVMs'
		)
);
const AccountVolumes = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountVolumes/AccountVolumes'
		)
);
const AccountDBs = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountDBs/AccountDBs'
		)
);
const AccountLogging = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountLogging/AccountLogging'
		)
);
const AccountResourcesCards = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountResourcesCards/AccountResourcesCards'
		)
);
const AccountResourcesList = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountResourcesList/AccountResourcesList'
		)
);
const AccountActionsOverview = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountActionsOverview/AccountActionsOverview'
		)
);
const LoggingBucketPage = lazy(
	() => import('../features/Resources/LoggingBucketPage/LoggingBucketPage')
);
const AccountGKE = lazy(
	() =>
		import(
			'../features/DiscoveryPage/AccountPage/AccountActions/AccountGKE/AccountGKE'
		)
);
const CUDSPage = lazy(() => import('../features/CUDS/CUDSPage'));

const getActionChildren = (
	resourceType: ResourceType,
	actionType: ResourceActionType
) => {
	return [
		{
			index: true,
			element: <Navigate to={ViewTypeRoutes.LIST} replace={true} />,
		},
		{
			path: ViewTypeRoutes.LIST,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountResourcesList
						resourceType={resourceType}
						action={actionType}
					/>
				</Suspense>
			),
		},
		{
			path: ViewTypeRoutes.CARDS,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountResourcesCards />
				</Suspense>
			),
		},
	];
};

const getResourceActionTypeChildren = (resourceType: ResourceType) => {
	return [
		{
			index: true,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<Navigate to={AccountActionsChildrenRoutes.SCALE} replace={true} />
				</Suspense>
			),
		},
		{
			path: AccountActionsChildrenRoutes.SCALE,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountActionsOverview
						action={ResourceActionType.SCALING}
						resourceType={resourceType}
					/>
				</Suspense>
			),
			children: getActionChildren(resourceType, ResourceActionType.SCALING),
		},
		{
			path: AccountActionsChildrenRoutes.DELETE,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountActionsOverview
						action={ResourceActionType.DELETE}
						resourceType={resourceType}
					/>
				</Suspense>
			),
			children: getActionChildren(resourceType, ResourceActionType.DELETE),
		},
		resourcePageConfig,
	];
};

const GKEChildren = [
	{
		index: true,
		element: (
			<Navigate to={AccountActionsChildrenRoutes.SCALE} replace={true} />
		),
	},
	{
		path: GKEChildrenRoutes.SCALE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountActionsOverview
					action={ResourceActionType.SCALING}
					resourceType={ResourceType.GKE}
				/>
			</Suspense>
		),
		children: getActionChildren(ResourceType.GKE, ResourceActionType.SCALING),
	},
	{
		path: GKEChildrenRoutes.DELETE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountActionsOverview
					action={ResourceActionType.DELETE}
					resourceType={ResourceType.GKE}
				/>
			</Suspense>
		),
		children: getActionChildren(ResourceType.GKE, ResourceActionType.DELETE),
	},
	{
		path: GKEChildrenRoutes.SUSPENDED,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountActionsOverview
					action={ResourceActionType.SUSPENDED}
					resourceType={ResourceType.GKE}
				/>
			</Suspense>
		),
		children: getActionChildren(ResourceType.GKE, ResourceActionType.SUSPENDED),
	},
	{
		path: GKEChildrenRoutes.RIGHTSIZE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountActionsOverview
					action={ResourceActionType.RIGHTSIZE}
					resourceType={ResourceType.GKE}
				/>
			</Suspense>
		),
		children: getActionChildren(ResourceType.GKE, ResourceActionType.RIGHTSIZE),
	},
	gkeResourcePageConfig,
];

const accountActionsChildren = [
	{
		index: true,
		element: (
			<Navigate to={AccountActionsChildrenRoutes.COMPUTE} replace={true} />
		),
	},
	{
		path: AccountActionsChildrenRoutes.COMPUTE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountVMS />
			</Suspense>
		),
		children: getResourceActionTypeChildren(ResourceType.COMPUTE),
	},
	{
		path: AccountActionsChildrenRoutes.VOLUMES,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountVolumes />
			</Suspense>
		),
		children: getResourceActionTypeChildren(ResourceType.VOLUME),
	},
	{
		path: AccountActionsChildrenRoutes.DATABASE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountDBs />
			</Suspense>
		),
		children: getResourceActionTypeChildren(ResourceType.DATABASE),
	},
	{
		path: AccountActionsChildrenRoutes.LOGGING,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountLogging />
			</Suspense>
		),
		children: [
			{
				index: true,
				element: <Navigate to='info' replace={true} />,
			},
			{
				path: 'info',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<AccountActionsOverview
							action={ResourceActionType.SCALING}
							resourceType={ResourceType.LOGGING}
						/>
					</Suspense>
				),
				children: getActionChildren(
					ResourceType.LOGGING,
					ResourceActionType.SCALING
				),
			},
			{
				path: 'resource/:resourceId',
				element: (
					<Suspense fallback={<LoadingSpinner />}>
						<LoggingBucketPage />
					</Suspense>
				),
			},
		],
	},
	{
		path: AccountActionsChildrenRoutes.GKE,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountGKE />
			</Suspense>
		),
		children: GKEChildren,
	},
];

const accountsInventoryChildren = [
	{
		index: true,
		element: (
			<Navigate
				to={`${AccountInventoryChildrenRoutes.INVENTORY}?`}
				replace={true}
			/>
		),
	},
	{
		path: AccountInventoryChildrenRoutes.INVENTORY,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<Allinventories />
			</Suspense>
		),
	},
	{
		path: 'volumes/:resourceType',
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AllVolumes />
			</Suspense>
		),
	},
	{
		path: 'computes/:resourceType',
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AllComputes />
			</Suspense>
		),
	},
	{
		path: 'databases/:resourceType',
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AllDatabases />
			</Suspense>
		),
	},
	{
		path: 'logs/:resourceType',
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AllLogs />
			</Suspense>
		),
	},
	{
		path: 'clusters/:resourceType',
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AllClusters />
			</Suspense>
		),
	},
	{
		path: AccountInventoryChildrenRoutes.ORGANISATION,
		index: true,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<InventoryOrganisation />
			</Suspense>
		),
	},
];
const accountPageChildren = [
	{
		index: true,
		element: <Navigate to={AccountChildrenRoutes.INVENTORY} replace={true} />,
	},
	{
		path: AccountChildrenRoutes.OVERVIEW,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountOverview />
			</Suspense>
		),
	},
	{
		path: AccountChildrenRoutes.INVENTORY,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<InventoryPage />
			</Suspense>
		),
		children: accountsInventoryChildren,
	},
	{
		path: AccountChildrenRoutes.CUDS,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<CUDSPage />
			</Suspense>
		),
	},
	{
		path: AccountChildrenRoutes.ACTIONS,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountActions />
			</Suspense>
		),
		children: accountActionsChildren,
	},
];

const accountsOverviewChildren = [
	{
		index: true,
		element: <Navigate to={`${ViewTypeRoutes.LIST}?`} replace={true} />,
	},
	{
		path: ViewTypeRoutes.LIST,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountsList />
			</Suspense>
		),
	},
	{
		path: ViewTypeRoutes.CARDS,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountsCards />
			</Suspense>
		),
	},
	{
		path: ViewTypeRoutes.MAP,
		element: (
			<Suspense fallback={<LoadingSpinner />}>
				<AccountsMap />
			</Suspense>
		),
	},
];

export const discoveryPageConfig = {
	path: `${CoreRoutes.DISCOVERY_PAGE}/:discoveryId?`,
	element: (
		<Suspense fallback={<LoadingSpinner />}>
			<DiscoveryPage />
		</Suspense>
	),
	children: [
		{
			index: true,
			element: (
				<Navigate to={DiscoveryChildrenRoutes.OVERVIEW} replace={true} />
			),
		},
		{
			path: DiscoveryChildrenRoutes.OVERVIEW,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountsOverview />
				</Suspense>
			),
			children: accountsOverviewChildren,
		},
		{
			path: `${DiscoveryChildrenRoutes.ACCOUNT_OVERVIEW}/:accountId`,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<AccountPage />
				</Suspense>
			),
			children: accountPageChildren,
		},
	],
};

import { RootState } from 'store';

export const selectedResources = (state: RootState) => state.discovery.selectedResources;
export const selectIsRefreshingToken = (state: RootState) => state.discovery.isRefreshingToken;
export const selectRefreshConnectorState = (state: RootState) => state.discovery.refreshConnectorState;
export const selectIsSingleProjectState = (state: RootState) => state.discovery.isSingleProject;
export const selectIsPolicyUpdate = (state: RootState) => state.discovery.isPolicyUpdated;
export const selectConnectionType = (state: RootState) => state.discovery.connectionType;
export const selectRediscoveryLoader = (state: RootState) => state.discovery.isRediscoveryLoader;
export const selectClusterReportData = (state: RootState) => state.discovery.clusterReportData;

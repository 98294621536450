import { client } from 'api/client';

export class PoliciesService {

    private static readonly path = 'api/policy'

    static capability(token: string) {
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return client.get<{capability: string[]}>(`${PoliciesService.path}/capability`);
    };

    static updatePolicy = (id: string | undefined, value: number) => client.patch(
        `${PoliciesService.path}`,
        {
            body:[{id,
            value,}]
            
        }
    );
}
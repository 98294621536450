import './BackButton.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button/Button';
import { ArrowBackIosNew } from '@mui/icons-material';

function BackButton() {
	const navigate = useNavigate();
	return (
		<Button
			className='back-button'
			onClick={() => navigate(-1)}
			text='Back'
			data_cyid='cy-execute-back-button'
			icon={<ArrowBackIosNew height='11px' width='11px' />}
		/>
	);
}

export { BackButton };

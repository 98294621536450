import { useCallback, useEffect, useMemo, useState } from 'react';
import './Dashboard.scss';
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { Tooltip } from 'recharts';
import { classNames, monthNames } from 'common/helpers';
import TableFilterHeader from 'components/TableFiltersHeader/TableFilterHeader';
import { Table } from 'components/Table/Table';
import DynamicPieChart from 'components/Charts/DynamicPieChart/DynamicPieChart';
import { CoreRoutes, DashboardChildrenRoutes } from 'routing/routing-paths';
import { AdminService } from 'api/services/admin.service';
import { AdminData, Client } from 'common/interfaces';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import DateRangeFilterDropdown from 'components/TimeRangeFilter/TimeRangeFilter';
import { UserImage } from 'components/UserImage/UserImage';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';

const MainStats = ({ data }: { data: AdminData }) => {
	const regionsBaseStats = useMemo(
		() =>
			data &&
			Object.entries(data?.region_wise_count).map(([key, value]) => ({
				name: key,
				value,
			})),
		[data]
	);

	const newClientStats = useMemo(() => {
		return [
			{ name: 'Old Clients', value: data?.total_clients - data?.new_clients },
			{ name: 'New Clients', value: data?.new_clients },
		];
	}, [data?.new_clients, data?.total_clients]);
	return (
		data && (
			<div className='admin-dashboard-component__main-stats'>
				<h1>Clients</h1>
				<div className='admin-dashboard-component__main-stats__container'>
					<div className='admin-dashboard-component__main-stats__container-content'>
						<h3 className='admin-dashboard-component__main-stats__container-content__title'>
							Paid
							<CustomizedTooltip
								tooltipContent={<span>Paid Clients has all the access</span>}
								placement='bottom-end'
								data_cyid='cy-tooltip-page-header-label'
							/>
						</h3>
						<span className='admin-dashboard-component__main-stats__container-content__value'>
							{data?.paid_clients}
						</span>
					</div>
					<div className='admin-dashboard-component__main-stats__container-content'>
						<h3 className='admin-dashboard-component__main-stats__container-content__title'>
							Trial
							<CustomizedTooltip
								tooltipContent={
									<span>Trial Clients that are on trial period</span>
								}
								placement='bottom-end'
								data_cyid='cy-tooltip-page-header-label'
							/>
						</h3>
						<span className='admin-dashboard-component__main-stats__container-content__value'>
							{data?.trial_clients}
						</span>
					</div>
					<div className='admin-dashboard-component__main-stats__container-content'>
						<h3 className='admin-dashboard-component__main-stats__container-content__title'>
							Active
							<CustomizedTooltip
								tooltipContent={
									<span>Clients that are actively using EazyOps.</span>
								}
								placement='bottom-end'
								data_cyid='cy-tooltip-page-header-label'
							/>
						</h3>
						<span className='admin-dashboard-component__main-stats__container-content__value'>
							{data?.active_clients}
						</span>
					</div>
					<div className='admin-dashboard-component__main-stats__container-content'>
						<h3 className='admin-dashboard-component__main-stats__container-content__title'>
							Inactive
							<CustomizedTooltip
								tooltipContent={
									<span>Clients that are inactive from past months.</span>
								}
								placement='bottom-end'
								data_cyid='cy-tooltip-page-header-label'
							/>
						</h3>
						<span className='admin-dashboard-component__main-stats__container-content__value'>
							{data?.inactive_clients}
						</span>
					</div>
				</div>
				<div className='stats-flex'>
					<div className='admin-dashboard-component__main-stats__client-charts'>
						<h3>Clients based in Regions</h3>
						<DynamicPieChart data={regionsBaseStats} />
					</div>
					<div className='admin-dashboard-component__main-stats__client-charts'>
						<h3>Total Clients</h3>
						<DynamicPieChart data={newClientStats} />
					</div>
				</div>
				<ClientsData data={data?.clients as Client[]} />
			</div>
		)
	);
};

const Revenue = ({ data }: { data: AdminData }) => {
	const result = useMemo(
		() =>
			data &&
			Object.entries(data.month_wise_revenue.monthly_billing).map(
				([key, value]) => {
					const monthIndex = parseInt(key) - 1;
					return { name: monthNames[monthIndex], value: value as number };
				}
			),
		[data]
	);

	return (
		<div className='admin-dashboard-component__revenue'>
			<h1>Revenue</h1>
			<div className='admin-dashboard-component__revenue__container'>
				<div className='admin-dashboard-component__revenue__container-content'>
					<h5 className='admin-dashboard-component__revenue__container-content__title'>
						Total Revenue
					</h5>
					<span className='admin-dashboard-component__revenue__container-content__value'>
						$ {data?.total_revenue}
					</span>
				</div>
				<div className='admin-dashboard-component__revenue__container-content'>
					<h5 className='admin-dashboard-component__revenue__container-content__title'>
						Monthly Revenue
					</h5>
					<span className='admin-dashboard-component__revenue__container-content__value'>
						$ {data?.monthly_revenue.toFixed(3)}
					</span>
				</div>
			</div>
			<div
				className='admin-dashboard-component__revenue__charts'
				style={{ width: '100%' }}
			>
				<ResponsiveContainer width='100%' height='100%'>
					<LineChart
						width={500}
						height={300}
						data={result}
						margin={{
							top: 20,
							right: 50,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='name' />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line
							type='monotone'
							dataKey='value'
							name='Revenue'
							stroke='#8884d8'
						/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

const ClientsData = ({ data }: { data: Client[] }) => {
	const [searchedValue, setSearchedValue] = useState<string>('');
	const rows = useMemo(
		() =>
			data &&
			data.map((item) => {
				return { ...item, name: item.full_name };
			}),
		[data]
	);

	const columns = [
		{
			name: 'Client Name',
			element: (resource: Client) => (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<UserImage size={35} userName={resource?.full_name} />
					<a
						className='resource-table-cell-name'
						style={{ color: 'blue' }}
						href={`${CoreRoutes.DASHBOARD}/${DashboardChildrenRoutes.CLIENT_OVERVIEW}/${resource?.id}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						{resource?.full_name}
					</a>
				</div>
			),
			maxWidth: '150px',
			sortable: true,
		},
		{
			name: 'Email',
			element: (resource: Client) => (
				<span className='resource-table-cell-name'>{resource?.email}</span>
			),
			maxWidth: '150px',
			sortable: true,
		},

		{
			name: 'Billing Amount',
			element: (resource: Client) => (
				<span className='accounts-table-cell-actual-cost'>
					{resource?.billing_amount}
				</span>
			),
			sortable: true,
		},
		{
			name: 'Clouds Connected',
			element: (resource: Client) => <span>{resource?.clouds_connects}</span>,
			sortable: true,
		},
		{
			name: 'Projects Connected',
			element: (resource: Client) => (
				<span className='accounts-table-cell-savings-percentage'>
					{resource?.projects_connected}
				</span>
			),
			sortable: true,
		},
	];

	return (
		data && (
			<div data-cyid='cy-client' className='client-list-component'>
				<div className='client-list-component-content'>
					<TableFilterHeader
						searchValue={searchedValue}
						setSearchValue={setSearchedValue}
						type='project'
						searchPlaceholder='Search by name...'
					/>

					<div className='client-list-component-content__wrapper'>
						<div className={classNames('client-data')}>
							<Table
								totalCount={data.length || 0}
								itemsPerPage={20}
								rows={rows || []}
								columns={columns}
								className='account-resources-list'
								placeholderText={
									searchedValue
										? // eslint-disable-next-line quotes
										"We couldn't find any data matching your search"
										: 'Data not found'
								}
								data_cyid='cy-table-clients-data'
								searchString={searchedValue}
								isSkeleton={!data}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

function Dashboard() {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [stats, setStats] = useState<AdminData | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [selectedOption, setSelectedOption] = useState<string | null>('30D');

	const getLatestStats = useCallback(
		(start_date?: Date | null, end_date?: Date | null) => {
			setLoading(true);
			AdminService.getLatesStats(
				start_date
					? start_date?.toISOString().split('T')[0]
					: startDate?.toISOString().split('T')[0],
				end_date
					? end_date?.toISOString().split('T')[0]
					: endDate?.toISOString().split('T')[0]
			)
				.then((response) => {
					setLoading(false);
					setStats(response.data as unknown as any);
				})
				.catch((err) => {
					setLoading(false);
					return err;
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		getLatestStats();
	}, [getLatestStats]);

	return isLoading ? (
		<LoadingSpinner />
	) : (
		<div
			data-cyid='cy-dashboard-component'
			className='admin-dashboard-component'
		>
			<div className='admin-dashboard-component__header'>
				<DateRangeFilterDropdown
					onClick={(startDate, endDate) => {
						getLatestStats(startDate, endDate);
					}}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
			</div>
			<div className='admin-dashboard-component__flex-container'>
				<div className='admin-dashboard-component__flex-container__left'>
					<MainStats data={stats as AdminData} />
				</div>
				<div className='admin-dashboard-component__flex-container__right'>
					<Revenue data={stats as AdminData} />
				</div>
			</div>
		</div>
	);
}

export default Dashboard;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
import {
	GKEAccessControlRoutes,
	GKEConfigRoutes,
	GKENetworkRoutes,
	GKEStorageRoutes,
	GKEWorkloadRoutes,
	ResourcePageChildRoutes,
} from './routing-paths';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import NodePools from 'features/Resources/GKEResourcePage/GKEResourceOverview/GKENodes/NodePools';

const ResourcePage = lazy(
	() => import('../features/Resources/ResourcePage/ResourcePage')
);
const ResourceOverview = lazy(
	() =>
		import(
			'../features/Resources/ResourcePage/ResourceOverview/ResourceOverview'
		)
);

const ResourcePolicy = lazy(
	() =>
		import('../features/Resources/ResourcePage/ResourcePolicy/ResourcePolicy')
);
const GKEResourcePage = lazy(
	() => import('../features/Resources/GKEResourcePage/GKEResourcePage')
);
const GKEResourceOverview = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEResourceOverview'
		)
);
const ClusterOverview = lazy(
	() =>
		import(
			'features/Resources/GKEResourcePage/GKEResourceOverview/ClusterOverview/ClusterOverview'
		)
);
const GKENodePools = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENodes/GKENodePools'
		)
);
const GKENodes = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENodes/GKENodes'
		)
);

const GKEClusterPods = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/Pods/ClusterPods'
		)
);
const GKEDeployments = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/Deployments/Deployments'
		)
);
const GKEDaemon = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/DaemonSets/DaemonSet'
		)
);
const GKEStatefulSets = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/StatefulSets/StatefulSets'
		)
);
const GKEReplicaSets = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/ReplicaSets/ReplicaSets'
		)
);
const GKEJobs = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/Jobs/Jobs'
		)
);
const GKEWorkload = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/Overview'
		)
);
const GKECronJobs = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/CronJobs/CronJobs'
		)
);
const WorkloadStats = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEWorkload/Stats/Stats'
		)
);
const GKENetwork = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENetwork/Overview'
		)
);
const NetworkServices = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENetwork/NetworkServices/NetworkServices'
		)
);
const NetworkEnpoints = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENetwork/Enpoint/Endpoints'
		)
);
const NetworkIngresses = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENetwork/Ingresses/Ingresses'
		)
);
const GKEConfigs = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEConfigs/Overview'
		)
);
const GKEConfigHPA = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEConfigs/HPA/HPA'
		)
);
const GKEConfigSecret = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEConfigs/Secrets/Secrets'
		)
);
const GKEConfigMaps = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEConfigs/Maps/ConfigMaps'
		)
);
const ClusterNamespaces = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKENamespace/GKENamespace'
		)
);
const ClusterEvents = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEEvents/GKEEvents'
		)
);
const GKEAccessControl = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/Overview'
		)
);
const GKEACClusterRoles = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/ClusterRoles/ClusterRoles'
		)
);
const GKEACServiceAccounts = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/ServiceAccounts/ServiceAccounts'
		)
);
const GKEACRoles = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/Roles/Roles'
		)
);
const GKEACRoleBindings = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/RoleBindings/RoleBindings'
		)
);
const GKEACClusterRoleBindings = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/ClusterRoleBindings/ClusterRoleBindings'
		)
);
const GKEACPodSecurityPolicies = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEAccessControl/PodSecurityPolicies/PodSecurityPolicies'
		)
);
const GKEStorage = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEStorage/Overview'
		)
);
const GKEPersistentVolumeClaims = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEStorage/PersistentVolumeClaims/PersistentVolumeClaims'
		)
);
const GKEPersistentVolumes = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEStorage/PersistentVolumes/PersistentVolumes'
		)
);
const GKEStorageClasses = lazy(
	() =>
		import(
			'../features/Resources/GKEResourcePage/GKEResourceOverview/GKEStorage/StorageClasses/StorageClasses'
		)
);

export const resourcePageConfig = {
	path: ':actionType/resource/:resourceId',
	element: (
		<Suspense fallback={<LoadingSpinner />}>
			<ResourcePage />
		</Suspense>
	),
	children: [
		{
			index: true,
			element: (
				<Navigate to={ResourcePageChildRoutes.OVERVIEW} replace={true} />
			),
		},
		{
			path: ResourcePageChildRoutes.OVERVIEW,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<ResourceOverview />
				</Suspense>
			),
		},
		{
			path: ResourcePageChildRoutes.POLICY,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<ResourcePolicy />
				</Suspense>
			),
		},
	],
};

// ---------------GKE Routes---------------------------
const workLoadChildConfig = [
	{
		index: true,
		element: <Navigate to={GKEWorkloadRoutes.OVERVIEW} replace={true} />,
	},
	{
		path: GKEWorkloadRoutes.OVERVIEW,
		element: <WorkloadStats />,
	},
	{
		path: GKEWorkloadRoutes.PODS,
		element: <GKEClusterPods />,
	},
	{
		path: GKEWorkloadRoutes.DEPLOYMENTS,
		element: <GKEDeployments />,
	},
	{
		path: GKEWorkloadRoutes.DAEMON_SETS,
		element: <GKEDaemon />,
	},
	{
		path: GKEWorkloadRoutes.REPLICA_SETS,
		element: <GKEReplicaSets />,
	},
	{
		path: GKEWorkloadRoutes.STATEFUL_SETS,
		element: <GKEStatefulSets />,
	},
	{
		path: GKEWorkloadRoutes.JOBS,
		element: <GKEJobs />,
	},
	{
		path: GKEWorkloadRoutes.CRON_JOBS,
		element: <GKECronJobs />,
	},
];

const networkChildConfig = [
	{
		index: true,
		element: <Navigate to={GKENetworkRoutes.SERVICES} replace={true} />,
	},
	{
		path: GKENetworkRoutes.SERVICES,
		element: <NetworkServices />,
	},
	{
		path: GKENetworkRoutes.ENDPOINTS,
		element: <NetworkEnpoints />,
	},
	{
		path: GKENetworkRoutes.INGRESSES,
		element: <NetworkIngresses />,
	},
];

const configChildConfig = [
	{
		index: true,
		element: <Navigate to={GKEConfigRoutes.CONFIG_MAPS} replace={true} />,
	},
	{
		path: GKEConfigRoutes.CONFIG_MAPS,
		element: <GKEConfigMaps />,
	},
	{
		path: GKEConfigRoutes.SECRETS,
		element: <GKEConfigSecret />,
	},
	{
		path: GKEConfigRoutes.HPA,
		element: <GKEConfigHPA />,
	},
];

const storageChildConfig = [
	{
		index: true,
		element: (
			<Navigate to={GKEStorageRoutes.PERSISTENT_VOLUME_CLAIMS} replace={true} />
		),
	},
	{
		path: GKEStorageRoutes.PERSISTENT_VOLUME_CLAIMS,
		element: <GKEPersistentVolumeClaims />,
	},
	{
		path: GKEStorageRoutes.PERSISTENT_VOLUMES,
		element: <GKEPersistentVolumes />,
	},
	{
		path: GKEStorageRoutes.STORAGE_CLASSES,
		element: <GKEStorageClasses />,
	},
];

const accessControlChildConfig = [
	{
		index: true,
		element: (
			<Navigate to={GKEAccessControlRoutes.SERVICE_ACCOUNTS} replace={true} />
		),
	},
	{
		path: GKEAccessControlRoutes.SERVICE_ACCOUNTS,
		element: <GKEACServiceAccounts />,
	},
	{
		path: GKEAccessControlRoutes.CLUSTER_ROLES,
		element: <GKEACClusterRoles />,
	},
	{
		path: GKEAccessControlRoutes.ROLES,
		element: <GKEACRoles />,
	},
	{
		path: GKEAccessControlRoutes.ROLE_BINDINGS,
		element: <GKEACRoleBindings />,
	},
	{
		path: GKEAccessControlRoutes.CLUSTER_ROLE_BINDINGS,
		element: <GKEACClusterRoleBindings />,
	},
	{
		path: GKEAccessControlRoutes.POD_SECURITY_POLICIES,
		element: <GKEACPodSecurityPolicies />,
	},
];

export const gkeResourcePageConfig = {
	path: 'resource/:resourceId',
	element: (
		<Suspense fallback={<LoadingSpinner />}>
			<GKEResourcePage />
		</Suspense>
	),
	children: [
		{
			index: true,
			element: (
				<Navigate to={ResourcePageChildRoutes.OVERVIEW} replace={true} />
			),
		},
		{
			path: ResourcePageChildRoutes.OVERVIEW,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<GKEResourceOverview />
				</Suspense>
			),
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={`${ResourcePageChildRoutes.OVERVIEW}/stats:name`}
							replace={true}
						/>
					),
				},
				{
					path: 'stats/:name',
					element: <ClusterOverview />,
				},
				{
					path: 'namespace/:name',
					element: <ClusterNamespaces />,
				},
				{
					path: 'events/:name',
					element: <ClusterEvents />,
				},
				{
					path: 'nodes/:name',
					element: <GKENodePools />,
					children: [
						{
							index: true,
							element: <Navigate to='list' replace={true} />,
						},
						{
							path: 'list',
							element: <GKENodes />,
						},
						{
							path: 'node-pools',
							element: <NodePools />,
						},
					],
				},
				{
					path: 'workload/:name',
					element: <GKEWorkload />,
					children: workLoadChildConfig,
				},
				{
					path: 'network/:name',
					element: <GKENetwork />,
					children: networkChildConfig,
				},
				{
					path: 'config/:name',
					element: <GKEConfigs />,
					children: configChildConfig,
				},
				{
					path: 'accesscontrol/:name',
					element: <GKEAccessControl />,
					children: accessControlChildConfig,
				},
				{
					path: 'storage/:name',
					element: <GKEStorage />,
					children: storageChildConfig,
				},
			],
		},
		{
			path: ResourcePageChildRoutes.POLICY,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<ResourcePolicy />
				</Suspense>
			),
		},
	],
};

import './PoliciesTileView.scss';
import { ReactComponent as ComputeIcon } from 'assets/svgs/policy-engine-compute.svg';
import { ReactComponent as VolumeIcon } from 'assets/svgs/policy-engine-volume.svg';
import { ReactComponent as DBIcon } from 'assets/svgs/policy-engine-database.svg';
import { ReactComponent as GKEIcon } from 'assets/svgs/policy-engine-cluster.svg';
import { ReactComponent as LogIcon } from 'assets/svgs/policy-engine-logs.svg';
import { ReactComponent as PodIcon } from 'assets/svgs/policy-engine-pod.svg';
import { ReactComponent as DeploymentIcon } from 'assets/svgs/policy-engine-deployment.svg';
import { IPolicies, PolicyEngineResource } from 'common/interfaces';
import {
	MouseEvent,
	SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Button } from 'components/Button/Button';
import { PoliciesService } from 'api/services/policies.service';
import PoliciesSlider from './PoliciesSlider/PoliciesSlider';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';
import { toast } from 'react-toastify';
import { client } from 'api/client';
import { LoadingProgress } from 'components/LoadingProgress/LoadingProgress';
import { formatDateTime, formatFullName, labelMapping } from 'common/helpers';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import DefaultPolicies from '../DefaultPolicies/DefaultPolicies';
import { useDispatch } from 'react-redux';
import { setPolicyUpdatedState } from 'store/discovery/discovery';

export const iconMapping: Record<string, JSX.Element> = {
	instance: <ComputeIcon height='30px' width='43px' />,
	disk: (
		<VolumeIcon height='30px' width='43px' color='#4285F4' fill='#AECBFA' />
	),
	database: <DBIcon height='30px' width='43px' />,
	cluster: <GKEIcon height='30px' width='43px' />,
	log: <LogIcon height='30px' width='43px' />,
	pod: <PodIcon height='30px' width='40px' />,
	deployment: <DeploymentIcon height='30px' width='43px' />,
	node: <DeploymentIcon height='30px' width='43px' />,
};

const PoliciesTileView = ({
	isResourceSelectionPopup,
	onClose,
}: {
	isResourceSelectionPopup?: boolean;
	onClose?: () => void;
}) => {
	const currentUser = useSelector(selectCurrentUser);
	const [policies, setPolicies] = useState<IPolicies | null>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedPolicies, setSelectedPolicies] = useState<
		Record<string, { id: string; name: string }>
	>({});
	const [selectedAction, setSelectedAction] = useState<
		Record<string, { id: string; name: string; value: string }>
	>({});
	const [showThreshold, setShowThreshold] = useState<Record<string, boolean>>(
		{}
	);
	const [policyThresholds, setPolicyThresholds] = useState<
		Record<string, number>
	>({});
	const [isSumbitting, setSubmitting] = useState<Record<string, boolean>>({});
	const dispatch = useDispatch();

	/**
	 * Fetches data from the API and updates the state with the fetched data.
	 */
	const getData = useCallback(() => {
		setIsLoading(true);
		client
			.get('/api/policy')
			.then(({ data }) => {
				setPolicies(data);
				localStorage.setItem('policies', JSON.stringify(data));
				isResourceSelectionPopup && dispatch(setPolicyUpdatedState(true));
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				return err;
			})
			.finally(() => {
				dispatch(setPolicyUpdatedState(false));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	/**Memoise policy data*/
	const policiesData = useMemo(() => {
		const appliedOnDataMap = new Map<string, any[]>();
		policies &&
			Object.entries(policies).forEach(
				([key, item]: [string, PolicyEngineResource[]]) => {
					const appliedOn = item[0].actions.applied_on;
					const policy = {
						id: item[0].actions.name,
						name: item[0].actions.name,
					};
					if (!selectedPolicies[appliedOn]?.id) {
						setSelectedPolicies((prev: any) => ({
							...prev,
							[appliedOn]: policy,
						}));
					}

					if (!appliedOnDataMap.has(appliedOn)) {
						appliedOnDataMap.set(appliedOn, []);
					}
					appliedOnDataMap.get(appliedOn)?.push(item);
				}
			);

		return appliedOnDataMap;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [policies]);

	/**
	 * Handles the selection of an action.
	 *
	 * @param opt - The selected option.
	 * @param appliedOn - The applied on value.
	 * @param firstOccurrence - The first occurrence data.
	 * @param policy - The policy data.
	 */
	const handleActionSelection = useCallback(
		(
			opt: { id: string; name: string; value: string },
			appliedOn: string,
			firstOccurrence: any,
			policy: Record<string, { id: string; name: string }> | null
		) => {
			if (selectedAction[appliedOn]?.value !== opt.value) {
				setSelectedAction(() => ({
					[appliedOn]: opt,
				}));
			}
			const selectedPolicyCriteia = policy
				? policy[appliedOn].name
				: selectedPolicies[appliedOn].name;
			const selectedStateData = firstOccurrence.filter(
				(item: any) => item.actions.name === selectedPolicyCriteia
			);
			const selectedActions = opt?.id;

			let selectedVariable = null;
			if (selectedStateData.length > 0) {
				const selectedStateVariables =
					selectedStateData[0]?.actions.variables[selectedActions];

				if (selectedStateVariables && selectedStateVariables.length > 0) {
					selectedVariable = selectedStateVariables[0].value;
				}
			}
			handleThresholdChange(selectedVariable, appliedOn);
		},
		[selectedAction, selectedPolicies]
	);

	/**
	 * Handles the selection of a policy.
	 *
	 * @param opt - The selected policy option.
	 * @param appliedOn - The target on which the policy is applied.
	 * @param firstOccurrence - The first occurrence of the policy.
	 */
	const handlePolicySelection = useCallback(
		(
			opt: { id: string; name: string },
			appliedOn: string,
			firstOccurrence: any
		) => {
			const policy = { [appliedOn]: opt };
			setSelectedPolicies((prev) => ({
				...prev,
				[appliedOn]: opt,
			}));
			if (selectedAction[appliedOn]?.id) {
				setTimeout(() => {
					handleActionSelection(
						selectedAction[appliedOn],
						appliedOn,
						firstOccurrence,
						policy
					);
				}, 200);
			}
		},
		[handleActionSelection, selectedAction]
	);

	/**
	 * Handles the update of a policy.
	 *
	 * @param ev - The mouse event that triggered the update.
	 * @param policy - The name of the policy to update.
	 * @param firstOccurrence - The first occurrence of the policy.
	 */
	const onUpdatePolicy = useCallback(
		(ev: MouseEvent<HTMLElement>, policy: string, firstOccurrence: any) => {
			ev.stopPropagation();
			setSubmitting((prev) => ({
				...prev,
				[policy]: true,
			}));
			const threshold = policyThresholds[policy] || 0;

			const selectedCriteriaID = firstOccurrence
				.map((item: any) => {
					if (
						item.actions.name === selectedPolicies[item.actions.applied_on].name
					) {
						return item.actions.variables[
							selectedAction[item.actions.applied_on].id
						][0].id;
					} else return null;
				})
				.filter((item: any) => item !== null);

			PoliciesService.updatePolicy(selectedCriteriaID[0], threshold)
				.then(({ data }) => {
					toast.success(
						`Policy ${data?.name} is updated for the resource ${policy}. Please, ensure a new discovery get the updated policy.`,
						{ autoClose: 10000 }
					);
					setSubmitting((prev) => ({
						...prev,
						[policy]: false,
					}));
					getData();
					isResourceSelectionPopup && onClose && onClose();
				})
				.catch((err) => {
					setSubmitting((prev) => ({
						...prev,
						[policy]: false,
					}));
					return err;
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[policyThresholds, selectedAction, selectedPolicies, getData]
	);

	/**
	 * Handles the change in threshold value for a policy.
	 * @param value - The new threshold value.
	 * @param policyId - The ID of the policy.
	 */
	const handleThresholdChange = (
		value: number | number[],
		policyId: string
	) => {
		if (typeof value === 'number') {
			setPolicyThresholds(() => ({
				[policyId]: value,
			}));
		}
	};

	/**
	 * Handles the change event of the slider.
	 *
	 * @param {Event | SyntheticEvent<Element, Event>} _ - The event object.
	 * @param {number | number[]} newValue - The new value of the slider.
	 * @param {string} id - The ID of the slider.
	 */
	const sliderChangeHandler = (
		_: Event | SyntheticEvent<Element, Event>,
		newValue: number | number[],
		id: string
	) => {
		_.stopPropagation();
		handleThresholdChange(newValue, id);
	};

	/**
	 * Resets the policy thresholds, selected policies, and selected actions.
	 *
	 * @param ev - The event object.
	 * @param appliedOn - The target appliedOn value.
	 */
	const handleReset = (
		ev: Event | SyntheticEvent<Element, Event>,
		appliedOn: string
	) => {
		ev.stopPropagation();

		setPolicyThresholds((prev) => ({
			...prev,
			[appliedOn]: 0,
		}));

		setSelectedPolicies((prev) => ({
			...prev,
			[appliedOn]: { id: '', name: '' },
		}));

		setSelectedAction((prev) => ({
			...prev,
			[appliedOn]: { id: '', name: '', value: '' },
		}));
	};

	/**
	 * Generates the dropdown options for the action dropdown based on the first occurrence of policies.
	 * @param firstOccurrence - The first occurrence of policies.
	 * @returns An array of dropdown options.
	 */
	const actionDropdownOptions = useCallback((firstOccurrence: any) => {
		const options: { id: string; name: string; value: string }[] = [];
		firstOccurrence.forEach((policy: any) => {
			const scalingOption = policy?.actions.variables?.scaling[0];
			const deleteOption = policy?.actions.variables?.delete[0];

			if (scalingOption && !options.some((opt) => opt.id === 'scaling')) {
				options.push({
					id: 'scaling',
					name: 'Scale',
					value: scalingOption.id,
				});
			}

			if (deleteOption && !options.some((opt) => opt.id === 'delete')) {
				options.push({
					id: 'delete',
					name: 'Delete',
					value: deleteOption.id,
				});
			}
		});

		return options;
	}, []);

	/**
	 * Handles the detailed view of a policy based on the appliedOn parameter.
	 * @param appliedOn - The identifier for the policy to show/hide the detailed view.
	 */
	const handleDetailedView = (appliedOn: string) => {
		setShowThreshold((prev) => ({
			...Object.fromEntries(Object.entries(prev).map(([key]) => [key, false])),
			[appliedOn]: !prev[appliedOn],
		}));
	};

	return (
		<div className='policies-tile-view'>
			{isLoading && <LoadingSpinner />}
			{policiesData &&
				Array.from(policiesData.entries()).map(([appliedOn, items], index) => {
					const firstOccurrence = items[0];
					const isHovered = showThreshold[appliedOn];
					const actionsDropdown = actionDropdownOptions(firstOccurrence);
					return (
						<div
							key={appliedOn}
							className={`policies-tile-view-wrapper ${isHovered ? 'policies-tile-view-wrapper-hovered' : ''
								}`}
							data-testid={`cy-policies-tile-${appliedOn}`}
						>
							{isSumbitting[appliedOn] && (
								<LoadingProgress text='Policy is being updated.' />
							)}
							<div
								className={`policies-tile-view-wrapper-item ${firstOccurrence[0]?.user
									? 'policies-tile-view-wrapper-item__updated'
									: 'policies-tile-view-wrapper-item__created'
									}`}
							>
								<div className='policies-tile-view-div'>
									<div>
										{!showThreshold[appliedOn] && (
											<div
												className='policies-tile-view-paper__flex-items-header'
												onClick={() => handleDetailedView(appliedOn)}
											>
												<div className='policies-tile-view-paper__flex-items-header-icon'>
													{iconMapping[appliedOn as keyof typeof iconMapping]}
												</div>
												<div className='text-bold'>
													{labelMapping[appliedOn]}
												</div>
											</div>
										)}

										{showThreshold[appliedOn] && (
											<div className='policy-engine-details__content'>
												<div
													className='policy-engine-details__content__flex-items-header'
													onClick={() => handleDetailedView(appliedOn)}
												>
													<div className='policies-tile-view-paper__item-icon'>
														{iconMapping[appliedOn as keyof typeof iconMapping]}
													</div>
													<div className='text-bold'>
														{labelMapping[appliedOn]}
													</div>
												</div>
												<DefaultPolicies policy={firstOccurrence} />
												<div className='header'>
													<div className='policy-engine-details__content__flex-items__title'>
														Policies Criteria
													</div>
													<div className='policy-engine-details__content__flex-items'>
														<div className='policies-tile-view-paper__stepper-container'>
															<Dropdown
																className='policies-engine-dropdown'
																dropdownPopoverClassName='policies-engine-dropdown-popover'
																options={firstOccurrence.map((policy: any) => ({
																	id: policy.actions.name,
																	name: policy.actions.name,
																}))}
																onOptionSelected={(opt) =>
																	handlePolicySelection(
																		opt,
																		appliedOn,
																		firstOccurrence
																	)
																}
																selected={selectedPolicies[appliedOn]}
																data_cyid='cy-dropdown-policy-engine-criteria-selection'
															/>
														</div>
													</div>
												</div>
												<div className='header'>
													<div className='policy-engine-details__content__flex-items__title'>
														Recommended Actions
														<CustomizedTooltip
															tooltipContent={
																<span>
																	Actions are based on the selected criteria.
																	Select <b>Policy Criteria</b>.
																</span>
															}
															placement='bottom-end'
															data_cyid='cy-tooltip-policy-engine-recommended-actions'
														/>
													</div>
													<div className='policy-engine-details__content__flex-items'>
														<div className='policies-tile-view-paper__stepper-container'>
															<Dropdown
																className='policies-engine-dropdown'
																dropdownPopoverClassName='policies-engine-dropdown-popover'
																options={actionsDropdown}
																onOptionSelected={(opt) =>
																	handleActionSelection(
																		opt,
																		appliedOn,
																		firstOccurrence,
																		null
																	)
																}
																selected={selectedAction[appliedOn]}
																disabled={!selectedPolicies[appliedOn]}
																data_cyid='cy-dropdown-policy-engine-actions-selection'
															/>
														</div>
													</div>
												</div>
												<div className='header'>
													<div className='policy-engine-details__content__flex-items__title'>
														Select your <b>{labelMapping[appliedOn]}</b>
														threshold:
													</div>
													<div className='policy-engine-details__content__flex-items'>
														<PoliciesSlider
															value={policyThresholds[appliedOn] || 0}
															onChange={(_, newValue) =>
																sliderChangeHandler(_, newValue, appliedOn)
															}
															onChangeCommitted={(_, newValue) =>
																sliderChangeHandler(_, newValue, appliedOn)
															}
														/>
													</div>
												</div>
												<div className='footer'>
													<Button
														className='small'
														text='Reset Default'
														onClick={(ev) => {
															ev.stopPropagation();
															handleReset(ev, appliedOn);
														}}
														disabled={
															!policyThresholds[appliedOn] ||
															isSumbitting[appliedOn]
														}
														data_cyid='cy-execute-reset-policy-threshold-action-button'
													/>
													<Button
														className='blue small policies-button'
														text='Update Policy'
														onClick={(event: MouseEvent<HTMLElement>) => {
															setTimeout(() => {
																onUpdatePolicy(
																	event,
																	appliedOn,
																	firstOccurrence
																);
															}, 500);
														}}
														disabled={
															policyThresholds[appliedOn] < 0 ||
															isSumbitting[appliedOn] ||
															!selectedAction[appliedOn] ||
															!selectedPolicies
														}
														data_cyid='cy-execute-update-policy-action-button'
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div
								className={`policies-tile-view-content ${isHovered ? 'policies-tile-view-content-hovered' : ''
									}`}
							>
								<h6>{firstOccurrence[0]?.user ? 'Updated' : 'Created'}</h6>
								<div>
									<span>
										<b>At: </b>
									</span>
									{formatDateTime(firstOccurrence[0].updated_at)}
								</div>
								<div className='by-name-container'>
									<span>
										<b>By: </b>
									</span>
									{formatFullName(
										firstOccurrence[0]?.user?.full_name ||
										currentUser?.full_name
									)}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default PoliciesTileView;

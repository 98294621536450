import { useMemo } from 'react';
import Slider from '@mui/material/Slider';
import { SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/system';

const StyledSlider = styled(Slider)(() => ({
	color: '#D9D9D9',
	height: 18,

	'& .MuiSlider-track': {
		borderRadius: 12,
		backgroundImage:
			'linear-gradient(90deg, #09D91D 0%, #FFAF36 120.02%, #F11 250.48%) ',
	},

	'& .MuiSlider-thumb': {
		width: 20,
		height: 20,
		border: '2px solid #4285F4',
		borderRadius: '50%',
		backgroundColor: '#FFFFFF',
		'&:hover, &.Mui-focusVisible': {
			boxShadow: '0px 0px 0px 8px rgba(255, 255, 255, 0.16)',
		},
	},

	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#4285F4',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
}));

const PoliciesSlider = ({ value, onChange }: SliderProps) => {
	const marks = useMemo(() => {
		if (typeof value === 'number')
			return [
				{
					value: 0,
					label: '0%',
				},
				{
					value: 100,
					label: '100%',
				},
				{
					value: value,
					label: `${value}%`,
				},
			];
	}, [value]);

	return (
		<StyledSlider
			onChange={onChange}
			value={value}
			defaultValue={50}
			aria-label='Default'
			valueLabelDisplay='auto'
			min={0}
			max={100}
			marks={marks}
		/>
	);
};

export default PoliciesSlider;

import './Notification.scss';
import { classNames } from 'common/helpers';
import { useTimeout } from 'hooks/useTimeout';
import Success from 'assets/svgs/notification-success.svg';
import Error from 'assets/svgs/notification-error.svg';
import Info from 'assets/svgs/notification-info.svg';

export enum NotificationSeverity {
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
}

export interface INotification {
	id: string;
	text: string;
	severity: NotificationSeverity;
}
export interface INotifications {
	id: string;
	user_id: string;
	type: string;
	message: string;
	status: boolean;
	seen: boolean;
	created_at: string;
}
interface NotificationProps {
	text: string;
	severity: NotificationSeverity;
	close: () => void;
}

const getIcon = (severity: NotificationSeverity) => {
	switch (severity) {
		case NotificationSeverity.ERROR:
			return Error;
		case NotificationSeverity.INFO:
			return Info;
		case NotificationSeverity.SUCCESS:
			return Success;
	}
};

function Notification({ severity, text, close }: NotificationProps) {
	useTimeout(15000, close);
	const icon = getIcon(severity);

	return (
		<div
			data-cyid='cy-notification-component'
			className='notification-container'
		>
			<div
				className={classNames('notification-component', severity)}
				onClick={close}
			>
				<div className='notification-component-icon'>
					<img alt='' src={icon} height='20px' width='20px' />
				</div>
				<div className='notification-component-text'>{text}</div>
			</div>
		</div>
	);
}

export { Notification };

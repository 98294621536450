import { useParams } from 'react-router-dom';
import './ClientOverview.scss';
import {
	CartesianGrid,
	ComposedChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { ReactComponent as LineIcon } from 'assets/svgs/lineChart.svg';
import { ReactComponent as SubPlanIcon } from 'assets/svgs/subplan.svg';
import { EmailOutlined, PersonPinCircleOutlined } from '@mui/icons-material';
import TableFilterHeader from 'components/TableFiltersHeader/TableFilterHeader';
import { Table } from 'components/Table/Table';
import { classNames, labelMapping, monthNames } from 'common/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DateRangeFilterDropdown from 'components/TimeRangeFilter/TimeRangeFilter';
import { ClockIcon } from '@mui/x-date-pickers';
import { AdminService } from 'api/services/admin.service';
import { ClientDetail, ProjectData } from 'common/interfaces';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { BackButton } from 'components/BackButton/BackButton';
import { iconMapping } from 'features/PolicyEngine/EazyOpsPolicies/PoliciesTileView/PoliciesTileView';
import { CustomizedTooltip } from 'components/Tooltip/Tooltip';

const ProjectsData = ({ data }: { data: ProjectData[] }) => {
	const [searchedValue, setSearchedValue] = useState('');

	const rows = useMemo(
		() =>
			data &&
			data.map((item, ind) => {
				return { ...item, name: item.project_name, id: ind.toString() };
			}),
		[data]
	);
	const columns = [
		{
			name: 'Projects',
			element: (resource: ProjectData) => (
				<span className='resource-table-cell-name'>
					{resource?.project_name}
				</span>
			),
			maxWidth: '150px',
			sortable: true,
		},

		{
			name: 'Discoveries',
			element: (resource: ProjectData) => (
				<span className='accounts-table-cell-actual-cost'>
					{resource?.dicoveries}
				</span>
			),
			sortable: true,
		},
		{
			name: 'Resource Types',
			element: (resource: ProjectData) => (
				<span>
					{resource?.resources.map((item) => (
						<CustomizedTooltip
							key={item}
							placement='top'
							data_cyid='cy-resources'
							tooltipContent={<span>{labelMapping[item]}</span>}
						>
							{iconMapping[item as keyof typeof iconMapping]}
						</CustomizedTooltip>
					))}
				</span>
			),
			sortable: true,
		},
		{
			name: 'Last Activity',
			element: (resource: ProjectData) => (
				<span>{resource?.last_activity}</span>
			),
			sortable: true,
		},
	];

	return (
		data && (
			<div data-cyid='cy-client' className='client-list-component'>
				<div className='client-list-component-content'>
					<TableFilterHeader
						searchValue={searchedValue}
						setSearchValue={setSearchedValue}
						type='project'
						searchPlaceholder='Search by name...'
					/>

					<div className='client-list-component-content__wrapper'>
						<div className={classNames('client-data')}>
							<Table
								totalCount={data.length || 0}
								itemsPerPage={20}
								rows={rows || []}
								columns={columns}
								className='account-resources-list'
								placeholderText={
									searchedValue
										? // eslint-disable-next-line quotes
										  "We couldn't find any data matching your search"
										: // eslint-disable-next-line quotes
										  "We couldn't find any data matching your filter"
								}
								data_cyid='cy-table-clients-data'
								searchString={searchedValue}
								isSkeleton={!data}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};
const ClientOverview = () => {
	const params = useParams();
	const [selectedOption, setSelectedOption] = useState<string | null>('30D');
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [stats, setStats] = useState<ClientDetail | null>(null);

	const getClientData = useCallback(
		(start_date?: Date | null, end_date?: Date | null) => {
			setLoading(true);
			AdminService.getClientStats(
				params?.clientID as string,
				start_date
					? start_date?.toISOString().split('T')[0]
					: startDate?.toISOString().split('T')[0],
				end_date
					? end_date?.toISOString().split('T')[0]
					: endDate?.toISOString().split('T')[0]
			)
				.then((response) => {
					setLoading(false);
					setStats(response.data as unknown as any);
				})
				.catch((err) => {
					setLoading(false);
					return err;
				});
		},
		[endDate, params?.clientID, startDate]
	);

	useEffect(() => {
		getClientData();
	}, [getClientData]);

	const result = useMemo(
		() =>
			stats &&
			Object.entries(stats.transactions_data).map(([key, value]) => {
				const monthIndex = parseInt(key) - 1;
				return { name: monthNames[monthIndex], value: value as number };
			}),
		[stats]
	);

	const sumValues = useCallback(() => {
		return (
			stats &&
			(Object.values(stats?.transactions_data as any).reduce(
				(sum: any, value: any) => sum + value,
				0
			) as number)
		);
	}, [stats]);

	return isLoading ? (
		<LoadingSpinner />
	) : (
		<div className='client-overview-component'>
			<BackButton />
			<div className='client-overview-component__info'>
				<h3 className='title'>{stats?.profile_data?.full_name}</h3>
				<div className='client-overview-component__info__details'>
					<div className='flex-box-container'>
						<EmailOutlined sx={{ height: '17px', width: '17px' }} />
						<span>{stats?.profile_data?.email}</span>
					</div>
					<div className='flex-box-container'>
						<ClockIcon sx={{ height: '17px', width: '17px' }} />
						<span>Last seen this week</span>
					</div>
					<div className='flex-box-container'>
						<PersonPinCircleOutlined sx={{ height: '17px', width: '17px' }} />
						<span>{stats?.profile_data?.location}</span>
					</div>
				</div>
			</div>
			<div className='client-overview-component__header'>
				<DateRangeFilterDropdown
					onClick={(startDate, endDate) => {
						getClientData(startDate, endDate);
					}}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				{/* <ReportGenerator
					selectedReport={selectedReport}
					setSelectedReport={setSelectedReport}
					type='overview'
					id={'ew'}
					title='Download & Save Report'
				/> */}
			</div>
			<div className='client-overview-component__client'>
				<div className='client-overview-component__client__savings'>
					<h3>Savings</h3>
					<LineIcon height={'200px'} width={'400px'} />
					<span className='client-overview-component__client__savings-amount'>
						$ {stats?.savings}
					</span>
				</div>
				<div className='client-overview-component__client__charts'>
					<h3>Transactions</h3>
					<div className='client-overview-component__client__charts-container'>
						<ResponsiveContainer width='100%' height='100%'>
							<ComposedChart
								width={200}
								height={200}
								data={result as Record<string, any>[]}
								margin={{
									top: 20,
									right: 20,
									bottom: 20,
									left: 20,
								}}
							>
								<defs>
									<linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
										<stop stopColor='#4285F4' stopOpacity={0.5} />
										<stop
											offset='0.75'
											stopColor='#264D8E'
											stopOpacity={0.94}
										/>
									</linearGradient>
								</defs>
								<CartesianGrid stroke='#f5f5f5' />
								<XAxis dataKey='name' scale='band' hide />
								<YAxis />
								<Tooltip />
								<Line type='monotone' dataKey='value' stroke='#ff7300' />
							</ComposedChart>
						</ResponsiveContainer>
					</div>
					<span className='client-overview-component__client__savings-amount'>
						$ {sumValues()}
					</span>
					<div className='client-overview-component__client__savings__trends'>
						<span className='time-span'>Last month</span>
					</div>
				</div>
				<div className='client-overview-component__client__plan'>
					<h3>Subscription </h3>
					<div className='client-overview-component__client__plan-active'>
						<div className='details'>
							<h4>Plan</h4>
							<span>Basic</span>
						</div>

						<SubPlanIcon height={'50px'} width={'60px'} />
					</div>
					<div className='client-overview-component__client__plan-amount'>
						<h4>Amount</h4>
						<span>$ {stats?.profile_data?.subscription_amount}</span>
						<span className='time-span'>Per month</span>
					</div>
				</div>
			</div>
			<div className='client-overview-component__tables'>
				<div className='client-overview-component__tables-container'>
					<h3 className='heading'>Discoveries & Projects</h3>
					<ProjectsData data={stats?.projects_data as ProjectData[]} />
				</div>
			</div>
		</div>
	);
};

export default ClientOverview;

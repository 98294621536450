import './GKENodes.scss';
import { Tile } from 'components/Tile/Tile';
import { IGKENodePools, IGKEResource } from 'common/interfaces';
import { ITableColumn, Table } from 'components/Table/Table';
import { useOutletContext } from 'react-router-dom';

const NodePools = () => {
	const { resource }: { resource: IGKEResource } = useOutletContext();
	const columns: ITableColumn<IGKENodePools>[] = [
		{
			name: 'Name',
			element: (resource: IGKENodePools) => (
				<div className='resource-table-cell-name blue'>{resource?.name}</div>
			),
			minWidth: '200px',
			sortable: true,
			sortKey: 'name',
		},

		{
			name: 'No. of Nodes',
			element: (resource: IGKENodePools) => (
				<div className='resource-table-cell-name'>
					{resource?.initial_node_count}
				</div>
			),
			minWidth: '200px',
			sortable: true,
			sortKey: 'initial_node_count',
		},
		{
			name: 'Machine Type',
			element: (resource: IGKENodePools) => (
				<div className='resource-table-cell-name'>{resource?.machine_type}</div>
			),
			minWidth: '200px',
			sortable: true,
			sortKey: 'machine_type',
		},
	];
	return (
		<div data-cyid='cy-gke-node-pools'>
			<div style={{ marginBottom: '10px', marginTop: '10px' }}>
				<Tile
					text={`Node Pools : ${resource?.normalizedObject?.node_pools?.length}`}
					color='blue'
				/>
			</div>

			<div className='gke-node-pools-component-content__wrapper'>
				<Table
					totalCount={resource?.normalizedObject?.node_pools?.length || 0}
					rows={resource?.normalizedObject?.node_pools || []}
					columns={columns}
					isSkeleton={!resource}
					className='node-pools-list'
					data-cyid='cy-node-pools-list-table'
					itemsPerPage={resource?.normalizedObject?.node_pools.length || 0}
					data_cyid='cy-table-cluster-node-pools'
					placeholderText='Node pools not available'
				/>
			</div>
		</div>
	);
};

export default NodePools;

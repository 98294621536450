import * as React from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import './Tooltip.scss';
import { ITooltipPlacement } from 'common/interfaces';
import { ReactComponent as InfoIcon } from 'assets/svgs/info.svg';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
	[`& .${tooltipClasses?.tooltip}`]: {
		backgroundColor: '#F6F6F6 !important',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 340,
		padding: '15px !important',
		fontSize: theme?.typography?.pxToRem(12),
		boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important',
		borderRadius: '5px',
	},
	[`& .${tooltipClasses?.arrow}`]: {
		color: 'rgba(0, 0, 0, 0.25)',
	},
}));

interface ICustomizedTooltip {
	children?: React.ReactElement;
	tooltipContent: React.ReactElement;
	placement: ITooltipPlacement;
	data_cyid: string;
	height?: string | number;
	width?: string | number;
}

function CustomizedTooltip({
	children,
	tooltipContent,
	placement,
	data_cyid = 'cy-customized-tooltip-component',
	height = '20px',
	width = '20px',
}: ICustomizedTooltip) {
	return (
		<HtmlTooltip
			data-cyid={data_cyid}
			placement={placement}
			title={<React.Fragment>{tooltipContent}</React.Fragment>}
		>
			{children || <InfoIcon height={height} width={width} />}
		</HtmlTooltip>
	);
}
export { CustomizedTooltip };

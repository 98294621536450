import { INotification, IPaginatedResponse } from 'common/interfaces';
import { client } from 'api/client';
import { currTimezone } from 'common/helpers';

export class NotificationService {

static readonly path = 'api/notification'
 
  static getNotificationsList(page?: number, size?: number) {
        return client.get<IPaginatedResponse<INotification>>(`${NotificationService.path}`,{params:{page,size, timezone:currTimezone}});
    }
   
    static markNotificationAsRead = (notificationIDs: string[]) => client.put(
        `${NotificationService.path}/mark-as-read`,
           [...notificationIDs]
        
    );
    static createNotification = (type: string,message:string) => client.post(
        `${NotificationService.path}`,
        {
           type,message
        }
    );
}
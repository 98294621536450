import { client } from 'api/client';
import { AdminData, ClientDetail } from 'common/interfaces';


export class AdminService {
    private static readonly path = 'admin-view';

    static getLatesStats = (startDate?: string | Date, endDate?:string |Date) => client.get<{data: AdminData}>(AdminService.path, {params: {start_date:startDate || null,end_date:endDate || null}});
    static getClientStats = (clientID:string, startDate?: string | Date, endDate?:string |Date) => client.get<{
		 data: ClientDetail
}>(`${AdminService.path}/client-detail`, {params: {client_id:clientID, start_date:startDate || null,end_date:endDate || null}});
}
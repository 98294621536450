import { client } from 'api/client';


export class ReportingService {
  static readonly path = '/api/reports';
  static readonly downloadedReportsPath = '/api/download_history';

  static getReports = () =>
    client.get(`${ReportingService.path}/list`);


  static createReport = (body: any) => client.post(ReportingService.path, body)

 
  static getReport = (id: string) =>
    client.get(ReportingService.path, { params: { id } });


  static getOrgReport = (type: string, id: string) =>
    client.get(`${ReportingService.path}/${type}/${id}`);


  static getOrgStats = (type: string, id: string, graphType: string) =>
    client.get(`${ReportingService.path}/${type}/graph/${graphType}/${id}`);


  static deleteReport = (id: string) => client.delete(
    `${ReportingService.path}/${id}`
  );

  static getDownloadedReports = () =>
    client.get(`${ReportingService.downloadedReportsPath}`);


  static createDownloadReport = (id:string) =>
    client.post(`${ReportingService.downloadedReportsPath}`, { id });

}

import { Container, Typography, Box } from '@mui/material';
import StickyHeader from './StickyHeader';
import './PrivacyPolicies.scss';

const PrivacyPolicy = () => {
	return (
		<Container className='privacy-policies-container'>
			<StickyHeader />
			<Container
				sx={{ p: '20px' }}
				className='privacy-policies-container-content'
			>
				<Typography variant='h4' gutterBottom>
					Privacy Policy
				</Typography>
				<Box mb={2}>
					<Typography>
						<b>EazyOps</b> ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by <b>EazyOps</b>. This Privacy Policy applies to our application named <b>EazyOps</b>, and its associated subdomains (collectively, our "Service"). By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>1. Information We Collect</Typography>
					<Typography>
						We may collect the following information:
					</Typography>
					<Box m={2}>
						<ul>
							<li style={{ marginBottom: '5px' }}>
								<Typography>
									Personal Data: Information such as your email address when you register or participate in activities related to the Service.
								</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography>
									Google Account Data: Information associated with your Google account, specifically limited to your email and cloud platform data when you use Google OAuth to connect to our Service.
								</Typography>
							</li>
						</ul>
					</Box>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>2. Use of Your Information</Typography>
					<Typography>
						Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
					</Typography>
					<Box m={2}>
						<ul>
							<li style={{ marginBottom: '5px' }}>
								<Typography>Provide recommendations based on your GCP usage to help reduce costs by suggesting downgrades of low-usage services.</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography>Monitor usage to improve our Service.</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography>Send account-related communications.</Typography>
							</li>
						</ul>
					</Box>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>3. Retention and Deletion of Data</Typography>
					<Box m={2}>
						<Typography>
							<b>Retention of Data:</b> We retain your data, including Google user account data (email and cloud platform data), for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy. Personal data will be retained until you request its deletion or stop using our services.
						</Typography>
					</Box>
					<Box m={2}>
						<ul>
							<li style={{ marginBottom: '5px' }}>
								<Typography>
									Personal data related to your account will be retained for the duration of your use of our services or as required for legal, financial, or operational reasons.
								</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography>
									Google user data will be retained in accordance with Google's data retention policies and will be deleted upon request from Google or in accordance with Google’s retention policies.
								</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography>
									Our website also periodically clears personal data after a 60-day period, unless it is needed for legal, regulatory, or operational purposes. This process ensures data is kept only as long as necessary.
								</Typography>
							</li>
						</ul>
					</Box>
					<Box m={2}>
						<Typography>
							<b>Deletion of Data:</b> If you wish to request the deletion of your personal data, including your email address or cloud platform data, you may contact us at <a href="mailto:info@eazyops.com">info@eazyops.com</a>. Upon receiving a valid deletion request, we will remove your personal data from our systems unless it is required to be retained under legal or regulatory obligations.
						</Typography>
					</Box>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>4. Disclosure of Your Information</Typography>
					<Typography>
						Your information may be disclosed as follows:
					</Typography>
					<Box m={2}>
						<ul>
							<li style={{ marginBottom: '5px' }}>
								<Typography><b>By Law or to Protect Rights:</b> If we believe that the release of information about you is necessary to comply with legal obligations, protect our rights, or protect the safety of others, we may share your information.</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography><b>Business Transfers:</b> In the event of any merger, sale of company assets, or acquisition, we may transfer your information to the acquiring company.</Typography>
							</li>
							<li style={{ marginBottom: '5px' }}>
								<Typography><b>Third-Party Service Providers:</b> We may share your information with third-party service providers that perform services for us, such as data analysis. These providers are prohibited from using your personal information for any other purpose.</Typography>
							</li>
						</ul>
					</Box>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>5. Security of Your Information</Typography>
					<Typography>
						We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of data transmission can be guaranteed to be completely secure.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>6. Use of Google APIs and Services</Typography>
					<Typography>
						Our service uses Google APIs, including Google OAuth for login and Google Cloud Platform (GCP) APIs to access your cloud platform data. We collect only the minimum necessary data (email and cloud inventory data) and provide recommendations to optimize your GCP costs. If you give access, you can apply these recommended actions directly from our app or via your GCP console.
					</Typography>
					<Typography>
						All Google user data collected is handled in accordance with Google’s User Data Policy and retained only as long as necessary to provide the service or as required by law.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>7. Changes to This Privacy Policy</Typography>
					<Typography>
						We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.
					</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>8. Your Data Rights</Typography>
					<Typography>You have the right to access, update, or request the deletion of your personal data. You may also withdraw consent at any time for data processing activities that require consent. To exercise these rights, please contact us at <a href="mailto:info@eazyops.com">info@eazyops.com</a>.</Typography>
				</Box>
				<Box mb={2}>
					<Typography variant='h5'>9. Contact Us</Typography>
					<Typography>
						If you have questions or comments about this Privacy Policy, please contact us at:
						<br />
						<strong>Email:</strong> <a href={'mailto:info@eazyops.com'} color='#4285f4'>info@eazyops.com</a>.
						<br />
						<strong>Address:</strong> 56 2nd St, San Francisco, CA 94105, United States.
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default PrivacyPolicy;

import { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IReportProjectInfo } from 'common/interfaces';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		borderBottomWidth: 1,
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		width: 100,
		minWidth: '175px',
		maxWidth: '175px',
	},

	arrowStyles: {
		width: 7,
		marginLeft: 2,
	},
	savingPercentage: { color: '#1EB564' },
});

const ProjectTableRow = ({ items }: { items: IReportProjectInfo[] }) => {
	const rows = items.map((item, index) => {
		return (
			<View style={styles.row} key={index}>
				<Text style={{ ...styles.rowItem }}>{item.project_name}</Text>
				<Text style={styles.rowItem}>{item.resources_count}</Text>
				<Text style={styles.rowItem}>${item.monthly_spending.toFixed()}</Text>
			</View>
		);
	});
	return <Fragment>{rows}</Fragment>;
};

export default ProjectTableRow;

import './App.scss';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { routing } from 'routing/routerConfig';
import { useRequestsErrorsInterceptor } from 'hooks/useRequestsErrorsInterceptor';

function App() {
	useRequestsErrorsInterceptor();

	return (
		<Suspense>
			<RouterProvider router={routing} />
		</Suspense>
	);
}

export default App;

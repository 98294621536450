import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { CoreRoutes } from 'routing/routing-paths';
import { getCookie } from 'common/helpers';
import { TOKEN_KEY } from 'common/constants';

interface GuestGuardProps {
	children: ReactElement;
}

const GuestGuard = ({ children }: GuestGuardProps) => {
	const token = getCookie(TOKEN_KEY);

	if (token) {
		return <Navigate to={CoreRoutes.DISCOVERIES} />;
	}

	return children;
};

export default GuestGuard;

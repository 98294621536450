import { classNames } from 'common/helpers';
import './Button.scss';
import { MouseEventHandler, ReactElement } from 'react';

interface ButtonProps {
	text: string;
	onClick?: MouseEventHandler | undefined;
	className: 'blue' | 'transparent' | 'black' | 'plain' | 'red' | string;
	icon?: ReactElement;
	disabled?: boolean;
	small?: boolean;
	xsmall?: boolean;
	isGhost?: boolean;
	large?: boolean;
	rounded?: boolean;
	data_cyid: string;
	title?: string;
}

function Button({
	text,
	onClick,
	className,
	icon,
	disabled,
	small,
	xsmall,
	rounded,
	isGhost,
	large,
	data_cyid = 'cy-button-component',
	title,
}: ButtonProps) {
	return (
		<button
			onClick={onClick}
			className={classNames(
				'button-component',
				className,
				(disabled || isGhost) && 'disabled',
				small && 'small',
				xsmall && 'xsmall',
				isGhost && 'ghost',
				rounded && 'rounded',
				large && 'large'
			)}
			disabled={disabled}
			data-cyid={data_cyid}
			title={title || ''}
		>
			{!!icon && icon}
			<span>{text}</span>
		</button>
	);
}

export { Button };

import { Suspense, lazy } from 'react';
import { CloudPricingChildRoutes, CoreRoutes } from './routing-paths';
import { Navigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

const CloudPricingPage = lazy(
	() => import('../features/CloudPricing/CPAccountsPage/CPAccountsPage')
);
const CloudPricingOverview = lazy(
	() => import('../features/CloudPricing/CPAccountOverview/CPAccountOverview')
);

const CloudPricingMain = lazy(
	() => import('../features/CloudPricing/CPAMain/CPAMain')
);

export const cloudPricingRouteConfig = {
	path: `${CoreRoutes.CLOUD_PRICING}/:id`,
	element: (
		<Suspense fallback={<LoadingSpinner />}>
			<CloudPricingMain />
		</Suspense>
	),
	children: [
		{
			index: true,
			element: (
				<Navigate to={CloudPricingChildRoutes.OVERVIEW} replace={true} />
			),
		},
		{
			path: CloudPricingChildRoutes.OVERVIEW,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<CloudPricingPage />
				</Suspense>
			),
		},
		{
			path: `${CloudPricingChildRoutes.ACCOUNT_OVERVIEW}/:accountId`,
			element: (
				<Suspense fallback={<LoadingSpinner />}>
					<CloudPricingOverview />
				</Suspense>
			),
		},
	],
};

import { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Cluster from 'assets/reportAssets/Cluster.png';
import Volumes from 'assets/reportAssets/Volume.png';
import Computes from 'assets/reportAssets/Compute.png';
import Database from 'assets/reportAssets/Databases.png';
import Logs from 'assets/reportAssets/Logs.png';
import { IReportResource } from 'common/interfaces';

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		borderBottomColor: '#CBD5E0',
		borderBottomWidth: 1,
		alignItems: 'center',
	},
	rowItem: {
		padding: '10px 10px',
		color: '#000000',
		fontFamily: 'Inter',
		fontWeight: 400,
		fontSize: 10,
		width: 100,
	},

	flexRow: {
		padding: '10px 10px',
		flexDirection: 'row',
		gap: '5px',
		alignItems: 'center',
		width: '125px',
	},
	resourceIcon: { width: '13.75px' },
	savingRows: {
		backgroundColor: 'rgba(194, 245, 219, 0.5)',
		border: '1px solid #A0EBC7',
		borderBottom: 'none',
		width: '135px',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '4px',
	},
});

const iconMapping: Record<string, string> = {
	clusters: Cluster,
	instances: Computes,
	disks: Volumes,
	databases: Database,
	logs: Logs,
};
const labelMapping: Record<string, string> = {
	disks: 'Volume',
	instances: 'Compute',
	databases: 'Database',
	clusters: 'Cluster',
	logs: 'Log',
	pods: 'Pod',
	deployments: 'Deployment',
};
const ReportTableRow = ({ items }: any) => {
	const rows = Object.keys(items).map((key: string, index: number) => {
		const item: IReportResource = items[key];
		const IconComponent = iconMapping[key];
		return (
			<View style={styles.row} key={key}>
				<View style={{ ...styles.flexRow }}>
					<Image style={styles.resourceIcon} src={IconComponent} />
					<Text style={{ ...styles.rowItem, padding: 0 }}>
						{labelMapping[key]}
					</Text>
				</View>
				<Text style={styles.rowItem}>${item?.monthly_cost.toFixed(2)}</Text>
				<Text style={styles.rowItem}>${item?.annual_cost.toFixed(2)}</Text>
				<Text style={[styles.rowItem, styles.savingRows]}>
					$
					{typeof item?.YR_1_cud_saving === 'string'
						? parseFloat(item?.YR_1_cud_saving as string).toFixed(2) || 0
						: (item?.YR_1_cud_saving as number).toFixed(2) || 0}
				</Text>
				<Text style={[styles.rowItem, styles.savingRows]}>
					${' '}
					{typeof item?.YR_3_cud_saving === 'string'
						? parseFloat(item?.YR_3_cud_saving as string).toFixed(2) || 0
						: (item?.YR_3_cud_saving as number).toFixed(2) || 0}
				</Text>
			</View>
		);
	});
	return <Fragment>{rows}</Fragment>;
};

export default ReportTableRow;
